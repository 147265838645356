<template>
  <div class="multicard">
    <div class="func">
      <div class="">
        <el-button size="small" @click="setTitle"> 修改标题 </el-button>
        <el-button size="small" @click="prvePage" :disabled='pageNum == 1 ? true : false'> 上一页 </el-button>
        <el-button size="small" @click="nextPage" :disabled='(pageSize * pageNum) >= story.length ? true : false'> 下一页 </el-button>
        <el-input size="small" style="width: 60px; margin: 0 10px;" v-model="pageNums" @input="pageNums = pageNums.replace(/^\.+|[^\d.]/g,'')" @keyup.native.enter="searchBtn" @blur="searchBtn"/>
        <el-button size="small">页数: {{ pageNum + '/' + Math.ceil(story.length / (pageSize || 1)) }}</el-button>
        <el-button size="small" @click="generateCard">生成PDF</el-button>
        <el-button size="small" @click="lookBack" v-if="is_lookBack">查看背面</el-button>
        <el-button size="small" @click="returnPositive" v-else>返回正面</el-button>
        <el-select v-model="measurement" placeholder="请选择" size="small" @change="changeSelect" style="width: 120px; margin: 0 50px">
          <el-option v-for="item in options" :key="item.value"
            :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 模型 -->
    <div class="mode">
      <div class="typeModel" id="model">
        <div class="templateOS">
          <div class="cte"></div>
          <div class="rightBottom"></div>
        </div>
      </div>

      <div class="back-typeModel" id="model"></div>
    </div>

    <div class="content" >
      <div class="model-position">
        <div class="">
          <!-- <el-button class="chioce-card" v-if="echoModel.length" size="small" @click="filterCard_child = true"> 选择样式 </el-button> -->
          <!-- 选择 -->
          <el-button class="chioce-card" size="small" @click="chioceCard"> 选择样式 </el-button>
          <!-- <el-button class="chioce-card" v-else size="small" @click="removeCard"> 新建卡样 </el-button> -->
        </div>

        <div class="echo-model" v-for="(its, idx) in echoModel" :key="idx" >
          <img :src="its.model.Cover" alt="">
          <div class="title">{{ its.modelName }}</div>
          <div class="mask-model">
            <i class="el-icon-edit" title="编辑" @click="clickModel(its)"></i>
            <i class="el-icon-delete" title="删除" @click="del(its.id, idx)"></i>
          </div>
        </div>
        
      </div>
      <div class="white-board" @dblclick="doubleClick">
        <!-- A4纸张 -->
        <div class="A4" @dblclick="doubleClick" :style="{'width': curModel.width * curModel.bl + 'px', 'height': curModel.height * curModel.bl + 'px'}">
          <!-- 定位孔 -->
          <div class="long-lt long">
            <div class="before"></div>
            <div class="after"></div>
            <div class="circular"></div>
          </div>
          <div class="long-rt long">
            <div class="before"></div>
            <div class="after"></div>
            <div class="circular"></div>
          </div>
          <div class="long-lb long">
            <div class="before"></div>
            <div class="after"></div>
            <div class="circular"></div>
          </div>
          <div class="long-rb long">
            <div class="before"></div>
            <div class="after"></div>
            <div class="circular"></div>
          </div>

          <!-- 页标题 -->
          <div class="page-title" v-if="fileTitle">{{ fileTitle }} - 第{{ pageNum }}页{{(is_lookBack ? '(正)' : '(背)')}}</div>

          <!-- 卡片 -->
          <!-- <div id="typeImg" class="typeImg" v-for="(its, idx) in typeImg" :key="idx"
           :style='{width: model.width+"px", height: model.height+"px" }'>
            <img :src="its" class="guide">
          </div> -->

        </div>
      </div>
      <div class="person">
        <div class="single" v-for="(its, idx) in typeList" :key="idx" @click.self="chioceType(its)">
          {{ its.modelName }}
          <i class="el-icon-delete" title="删除" @click="delectRow(its)"></i>
        </div>
      </div>
    </div>

    <!-- 排版配置 -->
    <el-dialog title="排版配置" :visible.sync="multicard_child" width="500px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <toConfigure ref="toConfigure" v-if="multicard_child" @showNum='showNum' @save='transition' :id='id' :model='curModel'></toConfigure>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="cancel" size="small" round>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 排版名称 -->
    <el-dialog title="排版名称" :visible.sync="styleTitle_child" width="20%" center class="model"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <el-input v-model="styleTitle" placeholder="请输入排版名称"></el-input>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="save" size="small" round>确 定</el-button>
        <el-button v-preventReClick @click="styleTitle_child = false" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>

    <!-- 排版名称 -->
    <el-dialog title="模板绑定人员" :visible.sync="getModelPerson_child" width="1200px" center class="model" :before-close='e => choiceBindPerson(false)'
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <getModelPerson ref="getModelPerson" :modelId='curEchoModel.id' :bindPersonMap='bindPersonMap' v-if="getModelPerson_child"></getModelPerson>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="choiceBindPerson" size="small" type="danger" round>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 文件 -->
    <el-dialog title="证卡文件" :visible.sync="chioceCard_child" width="1060px" center class="model"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <cardFile ref="Model" @fileEdit='fileEdit' :echoModel='echoModel' v-if="chioceCard_child"></cardFile>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="determine" size="small" type="danger" round>确 定</el-button>
        <el-button v-preventReClick @click="chioceCard_child = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 文件 -->
    <!-- <el-dialog title="证卡文件" :visible.sync="chioceCard_child" width="1060px" center class="model"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <cardFile ref="Model" @fileEdit='fileEdit' v-if="chioceCard_child"></cardFile>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="chioceCard_child = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog> -->

    <!-- 文件 -->
    <el-dialog title="证卡文件" :visible.sync="filterCard_child" width="1060px" center class="model"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <filterCard ref="Model" :curCardModel='initSize' :bindPersonMap='bindPersonMap' @fileEdit='fileEdit' v-if="filterCard_child"></filterCard>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="filterCard_child = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import multicard from './multicard.js'

export default multicard
</script>

<style lang="less" scoped>
@import url('./multicard.less');
</style>

<style lang="less">
.multicard {
  .el-dialog {
    margin: 80px  auto!important;
  }

  .back-typeModel, .typeModel {
    width: 500px;
    height: 281px;
    // background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
  }
  
}

.loding-size {
  font-size: 28px;
}
</style>