<template>
  <div class="sendRecord">
    <div class="model-list">
      <div class="single" v-for="(its, idx) in sendRecord" :key="idx">
        <img :src="its.cover" alt="">
        <div class="modelName"> {{ its.modelName }} </div>

        <!-- 标签 -->
        <div class="tips">
          <span class="no-handle" v-if="its.status == 0">待制作</span>
          <span class="approved" v-else-if="its.status == 1">制作中</span>
          <span class="fail" v-else-if="its.status == 2">制作完成</span>
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <div class="pagin">
      <el-pagination
      background
      @size-change="sizeChange"
      @current-change="curChange"
      :current-page="pageNum"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      sendRecord: [],

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 20,
    }
  },
  methods: {
    // 分页方法
    sizeChange(val){
      this.pageNum = 1
      this.pageSize = val

      this.getModel()
    },
    curChange(val){
      this.pageNum = val

      this.getModel()
    },
    // 获取发送记录
    getSendCardStatu() {
      let url = 'https://hzcard-1305256445.cos.ap-guangzhou.myqcloud.com'
      this.$http.post(this.API.getSendCardStatu(this.pageNum, this.pageSize))
        .then(res => {
          console.log(res)
          if(res.code == '000') {
            res.data.filter(its => {
              its.cover = url + its.cover + '?time=' + new Date().getTime()
            })
            this.sendRecord = res.data
            this.total = res.total
          } else {
            this.sendRecord = []
            this.total = 0
          }
        })
    }
  },
  mounted() {
    this.getSendCardStatu()
  }
}
</script>

<style lang="less" scoped>
.sendRecord {
  width: 100%;

  .model-list {
    width: 100%;
    max-height: 380px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;

    .single {
      margin: 5px;
      width: 240px;
      height: 180px;
      border: 1px dashed #ccc;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
        height: calc(100% - 25px);
        object-fit: contain;
      }

      .modelName {
        width: 100%;
        height: 20px;
        text-align: center;
        // line-height: 30px;
        letter-spacing: 1px;
        font-size: 14px;
        background-color: #eeeeee;
      }

      .tips {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

          span {
            padding: 3px 8px;
            color: #fff;
            font-size: 12px;
            border-radius: 20px;
            opacity: .9;
          }

          .no-handle {
            background-color: #999;
          }

          .approved {
            background-color: #f37036;
          }

          .fail {
            background-color: #52c41a;
          }
      }
    }
  }
}
</style>