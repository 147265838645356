<template>
  <div class="add">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="ruleForm.mobile" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input v-model="ruleForm.companyName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="设为默认">
        <el-switch v-model="ruleForm.defaultOption" :active-value='1' :inactive-value='0'></el-switch>
      </el-form-item>
      <el-form-item label="城市地址" prop="region">
        <el-cascader v-model="ruleForm.region" :options="options" :props="{ label: 'name', value: 'name' }"></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="detailedAddress">
        <el-input type="textarea" v-model="ruleForm.detailedAddress" autocomplete="off" style="resize: none;"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      }
      setTimeout(() => {
        let partent = new RegExp(/^1[0-9]{10}$/)
        if (!partent.test(value)) {
          callback(new Error('请输入有效的手机号'));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      // 城市 三级联动
      options: [],

      //form
      ruleForm: {
        name: '',
        phone: '',
        defaultOption: 0,
        region: '',
        detailedAddress: '',
        companyName: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        mobile: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        region: [
          { required: true, message: '请选择城市地址', trigger: 'blur' },
        ],
        detailedAddress: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  mounted() {
    this.$http.post(this.API.getCity)
      .then(res => {
        console.log(res)
        if(res.code == '000') {
          this.options = res.data[0].children
        } else {
          this.options = []
        }
      })
  }
}
</script>

<style lang="less" scoped>

</style>