<template>
  <div class="filterCard">
    
    <div class="model-list">
      <div class="single" v-for="(its, idx) in modelList" :key="idx"  @click="edit(its)">
        <img :src="its.model.Cover" alt="">

        <div class="modelName"> {{ its.modelName }} </div>

        <div class="hover" v-if="its.use">
          <!-- <i class="el-icon-edit" title="编辑" @click="edit(its)"></i>
          <i class="el-icon-delete" title="删除" @click="del(its.id, idx)"></i> -->
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <div class="pagin">
      <el-pagination
      background
      @size-change="sizeChange"
      @current-change="curChange"
      :current-page="pageNum"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 处理颜色 hex转为cmyk
import { convertTo } from '@/plugins/hex_cmyk.js'
import { mapMutations } from 'vuex'
import {family} from '../fontFamil.js'

export default {
  props: ['curCardModel', 'bindPersonMap'],
  data() {
    return {
      modelList: [],

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 20,
    }
  },
  methods: {
    // 分页方法
    sizeChange(val){
      this.pageNum = 1
      this.pageSize = val

      this.getModel()
    },
    curChange(val){
      this.pageNum = val

      this.getModel()
    },
    // 编辑
    edit(row) {
      if(!row.finalize) {
        this.$message.info('此证卡文件未定稿，不能进行多卡拼版!')
        return 
      }

      if(!row.use) this.$emit('fileEdit', row)
    },
    // 删除
    del(id, idx) {
      // if(this.modelId == )

      this.$confirm('是否删除该模板文件?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.API.delectModel(id))
          .then((res) => {
            // let res = JSON.parse(body)
            if(res.code == '000') {
              this.$message.success('删除成功！')
              this.modelList.splice(idx, 1)
              this.total--
            } else {
              this.$message.error(res.msg)
            }
          })
      }).catch(() => {})
    },
    uuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },  
    getModel() {
      var data = this.curCardModel,
      url = 'https://hzcard-1305256445.cos.ap-guangzhou.myqcloud.com',
      model = data.model
      this.$http.post(this.API.findCardBySize, {background: {width: model.width, height: model.height}})
      .then((res) => {
        // let res = JSON.parse(body)
        if(res.code == '000') {
          let arr = []
          res.data.filter(its => {
            var model, collection = []

            model = {
              Cover: url + its.cover + '?time=' + new Date().getTime(),
              width: its.width,
              height: its.height,
              color: convertTo('rgb', 'hex', its.color.splice(0, 3)),
              image: its.image,
              backImage: its.backImage,
              backColor: convertTo('rgb', 'hex', its.backColor.splice(0, 3)),
              plate: its.plate
            }

            its.elements && its.elements.filter(i => {
              if(i.eleType == "text"){
                collection.push({
                  id: this.uuid(),
                  x: i.x,
                  y: i.y,
                  width: i.width, // 元素宽度
                  height: i.height, // 元素高度
                  eleType: i.eleType, // 元素类型  image, text
                  opacity: i.opacity, // 透明度
                  variable: i.variable, // 变量
                  zIndex: i.zindex, // 层级
                  side: i.side, // 双面，单面 (twoSided， oneSide)
                  border: { // 边框, 形状 宽度 弧度 颜色
                    style: 'none',
                    width: 0,
                    radius: i.radio,
                    color: '', 
                  },
                  boxshadow: { // 阴影 - 大小 模糊程度 水平距离 垂直距离 颜色
                    size: 0,
                    vague: 0,
                    x: 0,
                    y: 0,
                    color: ''
                  },
                  conten: i.conten, // 内容
                  font: { // 字体 类型 大小 间距 行高 颜色 对齐方式 粗细 下划线 斜体
                    family: i.fonts ? family.filter(its => its.params == i.fonts && its)[0].value : 'yahei',
                    fonts: i.fonts,
                    size: i.fontSize,
                    spacing: 0, 
                    // lineHeight: 5,
                    color: convertTo('rgb', 'hex', i.color.splice(0, 3)),
                    textAlign: i.center,
                    fontWeight: '400',
                    textDecoration: 'none',
                    italics: 'normal'
                  }
                })
              } else {
                collection.push({
                  id: this.uuid(),
                  x: i.x,
                  y: i.y,
                  width: i.width, // 元素宽度
                  height: i.height, // 元素高度
                  eleType: i.eleType, // 元素类型  image, text
                  opacity: i.opacity, // 透明度
                  variable: i.variable, // 变量
                  zIndex: i.zindex, // 层级
                  side: i.side, // 双面，单面 (twoSided， oneSide)
                  image: i.image,
                  border: { // 边框, 形状 宽度 弧度 颜色
                    style: 'none',
                    width: 0,
                    radius: i.radio,
                    color: '', 
                  },
                  boxshadow: { // 阴影 - 大小 模糊程度 水平距离 垂直距离 颜色
                    size: 0,
                    vague: 0,
                    x: 0,
                    y: 0,
                    color: ''
                  },
                })
              }
            })
            // 筛选已添加的模型
            if(this.bindPersonMap[its.id]) its.use = true
            else its.use = false

            if(its.finalize == 1)
              arr.push({id: its.id, model, collection, modelName: its.modelName, use: its.use, finalize: its.finalize})
          })
          
          this.modelList = arr

          this.total = res.total
        }
      })
    }
  },
  mounted() {
    this.getModel()
  }
}
</script>

<style lang="less" scoped>
.filterCard {
  .model-list {
    width: 100%;
    max-height: 380px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;

    .single {
      margin: 5px;
      width: 240px;
      height: 180px;
      border: 1px dashed #ccc;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
        height: calc(100% - 30px);
        object-fit: contain;
      }

      .modelName {
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        letter-spacing: 1px;
        font-size: 16px;
        background-color: #eeeeee;
      }

      .hover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, .5);
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 25%;
        box-sizing: border-box;
        transition: all .5s;
        cursor: not-allowed;

        i {
          font-size: 20px;
          color: #fff;
          transition: color .5s;
        
          &:hover {
            color: #ccc;
          }

          &:active {
            color: #fff;
          }
        }

      }

      &:hover .hover {
        opacity: 1;
      }
    }
  }
}
</style>