<template>
  <div class="meetingInport">
    <div class="func">
      <el-select v-model="value" placeholder="请选择会议" @change="changeSelect">
        <el-option v-for="item in meetList" :key="item.value"
          :label="item.meetingName" :value="item.id">
        </el-option>
      </el-select>
    </div>

    <!--  -->
    <div class="contaner" v-if="value">
      <!-- 部门 -->
      <div class="operat"> 
        <!-- tree -->
        <div class="list-tree">
          <el-tree
            :data="data"
            ref='person-tree'
            :props="treeProps"
            node-key="id"
            :expand-on-click-node="false"
            :highlight-current="true"
            @node-click='treeClick'
            default-expand-all
            :render-content="renderContent">
          </el-tree>
        </div>
      </div>

      <div style="width: 100%">
        <!-- table -->
        <el-table ref="singleTable" @selection-change="batchDel"
          :data="tableData" border :height="height">
          <el-table-column :show-overflow-tooltip="true" align="center" :resizable='false' type="selection" width="50"></el-table-column>
          <el-table-column :show-overflow-tooltip="true" :prop="item.props" :label="item.label" :width="item.width"
            v-for="(item, idx) in tableCate" :key="idx"
            align="center" :resizable="false">
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="pagin">
          <el-pagination
          background
          @size-change="sizeChange"
          @current-change="curChange"
          :current-page="pageNum"
          :page-sizes="[20, 50, 100, 300]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
          </el-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { toTree, exportToExcel, Load } from '@/plugins/plugins.js'

export default {
  data() {
    return {
      data: [
        { id: 1, label: '全体参会人',
          children: []
        }
      ],
      treeProps: {
        children: 'children',
        label: 'confereeGroupName'
      },

      meetList: [],
      value: '',

      height: 416,
      tableData: [],
      tableCate: [
        {props: 'userName', label: '姓名', width: ''},
        {props: 'phone', label: '手机号', width: ''},
        {props: 'departmentName', label: '部门', width: ''},
        {props: 'characterId', label: '角色', width: ''},
        {props: 'attribute1', label: '组别', width: ''},
      ],

      curAttenGroup: {},

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 100,

      // 勾选后数据
      batchdata: [],
    }
  },
  methods: {
    // 选中人员
    batchDel(val) {
      this.batchdata = val
    },

    changeSelect(val) {
      this.$http.post(this.API.findConfereeGroupAllByMeetingId(val))
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000' && res.data) {
            // 手动补充属性 children
            res.data.filter(item => item.children = [])
            this.data = toTree(res.data)

            setTimeout(() => {
              document.querySelector('.atte-tree .el-tree-node__content').click()
              this.meetingData.pay == 1 && res.data[0].participationFee == 0 ? document.querySelector('.atte-tree .atten-money').click() : ''
            }, 200)
          }
        })
    },

    // tree - 树结构
    renderContent(h, { node, data, store }) {
      return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
          </span>
      );
    },

    // tree - 点击触发
    treeClick(data, node){
      this.curAttenGroup = data
      
      // 查询参会分组下的人员
      this.getAttenPerson()
    },
    // 分页方法
    sizeChange(val){
      this.pageNum = 1
      this.pageSize = val

      this.getAttenPerson()
    },
    curChange(val){
      this.pageNum = val

      this.getAttenPerson()
    },

    // 获取会议列表
    getMeetList() {
      this.$http.post(this.API.meetings).then(
        (res) => {
        // let res = JSON.parse(body)
          if(res.code == '000') {
            this.meetList = res.data
          } else {
            this.meetList = []
          }
        }
      )
    },
    // 查询参会分组下的参会人员
    getAttenPerson(){
      let arr = ['未确认', '已确认', '已报到', '已签到', '不参加'], 
        arr2 = ['', '请假', '请假', '请假']
      let obj = {
        contanUserIdArr: [],
        ifContanUserIdArr: false,
        queryConditionArr: []
      }

      this.$http.post(this.API.findByMeetingIdAndPage(this.value, this.curAttenGroup.id, this.pageNum, this.pageSize), obj)
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000' && res.data){
            res.data.filter((item, idx) => {
              item.confereeGroupName = this.curAttenGroup.confereeGroupName
              item.statusCode = item.statusCode == '' ? 0 : item.statusCode
              item.status = !item.leaveState ? arr[item.statusCode] : arr2[item.leaveState]
            })
            this.tableData = res.data
            this.total = res.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
    },
  },
  mounted() {
    this.getMeetList()
  }
}
</script>

<style lang="less" scoped>
.meetingInport {
  max-height: 500px;
  width: 100%;

  .func {
    width: 100%;
    height: 32px;
    margin-bottom: 10px;
  }

  .contaner {
    width: 100%;
    height: calc(100% - 42px);
    display: flex;

    .operat {
      width: 220px;
      height: 100%;
      margin-right: 20px;
    }

    .table {
      width: calc(100% - 240px);
      height: calc(100% - 42px);
    }
  }
}
</style>