// import { mapState } from 'vuex'

let properties = [
  {
    value: 'userName',
    lable: '姓名',
  },
  {
    value: 'sex',
    lable: '性别',
  },
  {
    value: 'phone',
    lable: '手机号',
  },
  {
    value: 'email',
    lable: '电子邮箱',
  },

  {
    value: 'oldDepartmentName',
    lable: '部门',
  },
  {
    value: 'ranksId',
    lable: '级别',
  },
  {
    value: 'duties',
    lable: '职务',
  },
  {
    value: 'characterId',
    lable: '角色',
  },
  {
    value: 'attribute1',
    lable: '组别',
  },

  // {
  //     value:'type',
  //     lable:'类别',
  // },
  // {
  //     value:'attribute1',
  //     lable:'属性1',
  // },
  // {
  //     value:'attribute2',
  //     lable:'属性2',
  // },
  // {
  //     value:'attribute3',
  //     lable:'属性3',
  // },
  // {
  //     value:'attribute4',
  //     lable:'属性4',
  // },
]
let condies = ['and', 'or']
let oprates = [
  {
    lable: '=',
    value: 'eq'
  },
  {
    lable: '≠',
    value: 'ne'
  },
  {
    lable: '>',
    value: 'gt'
  },
  {
    lable: '≥',
    value: 'ge'
  },
  {
    lable: '<',
    value: 'lt'
  },
  {
    lable: '≤',
    value: 'le'
  },
]
function AddCondis() {
  return {
    fieldName: 'userName',
    conditionalSign: 'eq',
    value: '',
    logicalSymbol: 'and'
  }
}

export default {
  props: ['curDepar', 'bool', 'departType'],
  // components: {
  //   SearchUser
  // },
  data() {
    return {
      height: null,
      tableLable: [
        { name: "条件组名称", fieldName: "queryBuilderName" }
        // { name: "部门", fieldName: "departmentId" }
        // { name: "职务", fieldName: "duties" },
        // { name: "手机号", fieldName: "phone" },
        // { name: "email", fieldName: "email" },
        // { name: "角色", fieldName: "characterId" },
        // { name: "组别", fieldName: "groups" },
        // { name: "级别", fieldName: "ranksId" },
        // { name: "类别", fieldName: "type" },
        // { name: "属性1", fieldName: "attribute1" }, //attribute1
        // { name: "属性2", fieldName: "attribute2" }, //attribute2
        // { name: "属性3", fieldName: "attribute3" }, //attribute3
        // { name: "属性4", fieldName: "attribute4" } //attribute4
      ],
      preGroup: [],
      checkData: [],
      delCon: [],
      centerDialogVisible: false,

      properties: properties,
      condies: condies,
      oprates: oprates,

      param: {
        queryBuilderName: '',
        queryConditionList: []
      },
      
      departs: [],
      departProps: {
        expandTrigger: 'hover',
        value: 'id',
        label: 'departmentName',
        children: 'child',
        emitPath: false,
        checkStrictly: true,
      },

      name: '',
      // bool: true,
      editBool: true,

    };
  },
  methods: {
    setsearchcondition: function (val) {
      console.log(val);
      //   this.searchCondition = val;
      //   this.searchKey = "";
      //   this.searchData();
    }, //setsearchcondition
    // 查看条件组内容
    handleSelectionChange(val) {
      console.log("查看条件组内容", JSON.stringify(val, null, 2))
      this.checkData = JSON.parse(JSON.stringify(val))
      this.param = JSON.parse(JSON.stringify(val))
      if (val.queryBuilderName) {
        this.bool = false
        this.editBool = false
      }
    },

    addCondis: function () {
      this.param.queryConditionList.push(AddCondis())
    },//addCondis
    removeCondis: function (index) {
      this.param.queryConditionList.splice(index, 1)
    },//removeCondis
    search: function () {
      console.log(this.param)
      this.setsearchcondition(this.param)
    },//search
    cleanCondis: function () {
      this.param.queryConditionList = []
    },//cleanCondis

    // 保存
    preservation(type) {
      console.log(this.param)
      let that = this
      if (!this.param.queryBuilderName || !this.param.queryBuilderName.trim()) {
        this.$message("请输入条件组名称！")
        return
      }

      if (this.param.queryConditionList.length == 0) {
        this.$message("请添加查询条件！")
        return
      }

      if (!this.bool && this.editBool) {
        var data = {
          id: this.curDepar.id,
          externalCode: '0',
          departmentName: this.param.queryBuilderName,
          queryCondition: this.param.queryConditionList,
          queryConditionString: JSON.stringify(this.param.queryConditionList)
        }
      } else {
        // 处理数据 condition
        this.param.queryConditionList.map((item, idx) => this.param.queryConditionList[idx].sequenceNumber = '' + idx)
        var data = {
          externalCode: '0',
          departmentName: this.param.queryBuilderName,
          queryCondition: this.param.queryConditionList,
          queryConditionString: JSON.stringify(this.param.queryConditionList)
        }
      }

      if (type == 'new') {
        this.$emit('addQuery', data)
      } else {
        this.$emit('editAddDepart', data)
      }

    }, // preservation
    // 修改
    edit() {
      this.editBool = true
    },
    // 退出编辑
    signOutEdit() {
      this.editBool = true
      this.bool = true
      this.param = {
        queryConditionList: []
      }
      this.name = ''
    }
  },
  mounted() {
    console.log(this.curDepar)
    // 表格高度
    // var dom = document.querySelector('.c-table')
    // this.height = dom.offsetHeight
    if(!this.bool){
      this.param = JSON.parse(JSON.stringify({
        queryBuilderName: this.curDepar.departmentName,
        queryConditionList: JSON.parse(this.curDepar.QueryConditionString)
      }))

    }
  },
  watch: {
    param: function (val) {
      console.log(val)
      if (this.param.length > 0) {
        this.param[0].condis = 'and'
      }
    },//param

  },
};