<template>
  <div class="address">
    <div class="func">
      <el-button size="small" @click="add_switch = true">添加地址</el-button>
    </div>

    <div class="table">
      <el-table ref="singleTable" empty-text="暂无地址数据"
        :data="tableData" border :height="height">
        <el-table-column :show-overflow-tooltip="true" type="index" width="50" label="序号" align="center" :resizable="false"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" :prop="item.props" :label="item.label" :width="item.width"
          v-for="(item, idx) in tableCate" :key="idx"
          align="center" :resizable="false">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" align="center" :resizable='false' label="地址" width=''>
          <template slot-scope="scope">
            <div class="">
              <div>{{ scope.row.region }}</div>
              <div>{{ scope.row.detailedAddress }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" align="center" :resizable='false' label="默认地址" width='100'>
          <template slot-scope="scope">
            <el-switch v-model="scope.row.defaultOption" :active-value='1' :inactive-value='0' @change="changeOption(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" align="center" :resizable='false' label="操作" width='160'>
          <template slot-scope="scope">
            <el-button size="small" @click="edit(scope.row)">修改</el-button>
            <el-button size="small" type="danger" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加地址 -->
    <el-dialog title="添加" :visible.sync="add_switch" width="600px" center append-to-body
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <add v-if="add_switch" ref="add"></add>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="addArress" size="small" type="danger" round>添 加</el-button>
        <el-button v-preventReClick @click="add_switch = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 添加地址 -->
    <el-dialog title="修改" :visible.sync="edit_switch" width="600px" center append-to-body
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <edit v-if="edit_switch" ref="edit" :ruleForm='ruleForm'></edit>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="editArress" size="small" type="danger" round>修 改</el-button>
        <el-button v-preventReClick @click="edit_switch = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import add from './add/add.vue'
import edit from './edit/edit.vue'

export default {
  components: {
    add,
    edit
  },
  data() {
    return {
      add_switch: false,
      edit_switch: false,

      // table
      height: 500,
      tableData: [],
      tableCate: [
        {props: 'name', label: '姓名', width: '120'},
        {props: 'mobile', label: '手机号', width: '100'},
        {props: 'companyName', label: '公司名称', width: '160'},
      ],

      // 修改数据
      ruleForm: {}
    }
  },
  methods: {
    addArress() {
      let child = this.$refs.add,
      ruleForm = {...child.ruleForm}
      ruleForm.region = ruleForm.region.join('')

      console.log(ruleForm)
      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$http.post(this.API.addHarvestAddress, ruleForm)
            .then(res => {
              console.log(res)
              if(res.code == '000') {
                this.$message.success('添加成功!')
                this.add_switch = false
                this.getAddressData()
              } else {
                this.$message.error(res.msg)
              }
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    editArress() {
      let child = this.$refs.edit,
      ruleForm = {...child.ruleForm}
      ruleForm.region = ruleForm.region.join('')

      console.log(ruleForm)
      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$http.post(this.API.addHarvestAddress, ruleForm)
            .then(res => {
              console.log(res)
              if(res.code == '000') {
                this.$message.success('修改成功!')
                this.edit_switch = false
                this.getAddressData()
              } else {
                this.$message.error(res.msg)
              }
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    
    edit(row) {
      this.ruleForm = {...row}
      this.ruleForm.region = row.region.split('')
      delete this.ruleForm.address

      this.edit_switch = true
    },

    // 更改默认地址
    del(row) {
      this.$confirm('是否删除该地址?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.API.delectHarvestAddress(row.id))
          .then(res => {
            if(res.code == '000') {
              this.$message.success('删除成功!')
              this.getAddressData()
            } else {
              this.$message.error(res.msg)
            }
          })
      }).catch(() => {})
    },

    // 更改默认地址
    changeOption(row) {
      let ruleForm = {...row}
      
      this.$http.post(this.API.addHarvestAddress, ruleForm)
        .then(res => {
          console.log(res)
          if(res.code == '000') {
            this.$message.success('修改成功!')
            this.edit_switch = false
            this.getAddressData()
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 查询地址管理数据
    getAddressData() {
      this.$http.post(this.API.findHarvestAddress)
        .then(res => {
          if(res.code == '000') {
            res.data.sort((cur, next) => cur.createTime - next.createTime)
            .filter(its => {
              its.address = its.region + '\r\n' + its.detailedAddress
            })
            this.tableData = res.data
          } else {
            this.tableData = []
          }
        })
    }
  },
  mounted() {
    this.getAddressData()
  }
}
</script>

<style lang="less" scoped>
.address {
  width: 100%;
  user-select: text;

  .func {
    width: 100%;
    padding-bottom: 10px;
    height: 42px;
    box-sizing: border-box;
  }
}
</style>