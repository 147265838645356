import { mapState } from 'vuex'

// 添加人员js
function User() {
	return { 
		userName: '', //姓名
		sex: '男', //性别
		birthday: 0, //生日
		cardNum: 1, //制作数量
		nation: '', //民族 
		political: '', //政治面貌
		party: '', //党派
		companyId: '', //工作单位
		education: '', //学历
		oldDepartmentName: '', //部门bthc3l3rm5p9151j3ih0
		duties: '', //职务
		characterId: '', //角色
		ranksId: '', //级别
		phone: '', //手机
		email: '', //email
		weChat: '', //微信
		qq: '', //QQ
		homeAddress: '', //家庭住址
		photoFileId: '', //头像ID
		groups: '', //组别
		type: '', //类别
		attribute1: '', // 组别
		attribute2: '', //属性2
		attribute3: '', //属性3
		attribute4: '', //属性4
		externalCode: '0',
		photoFileSaveName: ''
	}
}

export default {
  props: ['tableCate', 'varName'],
  data() {
    return {
      user: new User(),
      cardNum: 1

    }
	},
  computed: {
    ...mapState(['modelId'])
  },
  methods: {
    // 上传头像
    upload(){
      let file = this.$refs.avatar, files
      files = file.files[0]
      
      this.fileUpload(files, 'HeadFile', res => {
        if(res.code == '000'){
          this.$message.success('上传成功！')
          this.user.photoFileSaveName = res.data.saveFileName
        } else {
          this.$message.error(res.msg)
        }

        file.value = ''
      }, this.modelId)
    },

  },
	watch: {
    cardNum: function(val) {
      this.user.cardNum = val
    }
  },
  mounted() {
    
  }
}