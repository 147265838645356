<template>
  <div class="toConfigure">
    <div class="story">
      <el-button size="small" @click="prvePage('edit')" v-if="id"> 修改排版样式 </el-button>
      <el-button size="small" @click="prvePage('add')" > 保存排版样式 </el-button>
    </div>

    <div class="category">页面大小</div>
    <div class="model-attr">
      <div class="single">
        <span class="label">宽度：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.width" :min="0" @change="modelWidth"></el-input-number>
        </div>
      </div>
      <div class="single">
        <span class="label">高度：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.height" :min="0" @change="modelHeight"></el-input-number>
        </div>
      </div>
    </div>

    <div class="category">卡片距离</div>
    <div class="model-attr">
      <div class="single">
        <span class="label">上下间距：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.udSpacing" :min="0" @change="cardUdSpacing"></el-input-number>
        </div>
      </div>
      <div class="single">
        <span class="label">左右间距：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.aboutSpacing" :min="0" @change="cardAboutSpacing"></el-input-number>
        </div>
      </div>
    </div>

    <div class="category">页边距</div>
    <div class="model-attr">
      <div class="single">
        <span class="label">上边距：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.topSpacing" :min="0" @change="modelTopSpacing"></el-input-number>
        </div>
      </div>
      <div class="single">
        <span class="label">左边距：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.leftSpacing" :min="0" @change="modelLeftSpacing"></el-input-number>
        </div>
      </div>
      <div class="single">
        <span class="label">下边距：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.bottomSpacing" :min="0" @change="modelBottomSpacing"></el-input-number>
        </div>
      </div>
      <div class="single">
        <span class="label">右边距：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.rightSpacing" :min="0" @change="modelRightSpacing"></el-input-number>
        </div>
      </div>
    </div>
    
    <div class="category">辅助线选项</div>
    <div class="model-attr">
      <div class="single">
        <span class="label">卡片边框：</span>
        <div class="value">
          <el-checkbox v-model="model.cardBorder" @change="cardBorder"></el-checkbox>
        </div>
      </div>
      <div class="single">
        <span class="label">边框颜色：</span>
        <div class="value">
          <colorPicker v-model="model.cardBorderColor" @change="cardBorderColor"/>
        </div>
      </div>

      <div class="single">
        <span class="label">定位孔：</span>
        <div class="value">
          <el-checkbox v-model="model.locatingHole" @change="locatingHole"></el-checkbox>
        </div>
      </div>
      <div class="single">
        <span class="label">定位边距：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.locatingMargin" :min="0" @change="locatingMargin"></el-input-number>
        </div>
      </div>
      <div class="single">
        <span class="label">直径：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.locatingDiameter" :min="0" @change="locatingDiameter"></el-input-number>
        </div>
      </div>
    </div>

    <div class="category">输出选择项</div>
    <div class="model-attr">
      <div class="single">
        <span class="label">分辨率：</span>
        <div class="value">
          <el-select v-model="model.dpi" placeholder="请选择分辨率" size="small" style="width: 130px">
            <el-option label="300dpi" :value="300"> </el-option>
            <el-option label="500dpi" :value="500"> </el-option>
          </el-select>
        </div>
      </div>

      <div class="single">
        <span class="label">文件输出量：</span>
        <div class="value">
          <el-input-number size="small" v-model="model.outputNum" :min="1" :max="320" @change="changeOutputNum"></el-input-number>
          <div class="tips">最多不能超过320张</div>
        </div>
      </div>
    </div>

    <!-- 排版名称 -->
    <el-dialog title="排版名称" :visible.sync="typeName_child" width="20%" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <el-input v-model="typeName" placeholder="请输入排版名称"></el-input>
      <div class="dialog-btn">
        <!-- <el-button v-preventReClick type="primary" @click="prvePage" size="small" round>确 定</el-button>
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>取 消</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  props: ['model', 'id'],
  data() {
    return {
      bl: 5,

      // 纸张dom
      paper: null,
      // 图片父盒子dom
      photoBox: null,
      // 辅助线
      guide: null,
      // 定位孔
      circular: null,

      typeName: '',
      typeName_child: false,
    }
  },
  methods: {
    prvePage(t) {
      this.$emit('save', t)
    },

    changeOutputNum(val) {
      this.$emit('changeOutputNum', val)
    },

    modelWidth(val) {
      this.paper.css('width', val*this.model.bl+'px')
      let A4 = $('.A4').outerWidth(),
      whiteBoard = $('.white-board').outerWidth()
      if(A4 > whiteBoard) {
        $('.A4').css({'left': '0',})
        $('.white-board').css({'overflow-x': 'auto',})
      } else {
        $('.A4').css({'left': 'auto',})
        $('.white-board').css({'overflow-x': 'hidden',})
      }
    },
    modelHeight(val) {
      this.paper.css('height', val*this.model.bl+'px')
    },
    cardUdSpacing(val) {
      console.log(val)
      this.photoBox.css({
        'margin-top': val*this.model.bl + 'px',
        'margin-bottom': val*this.model.bl + 'px',
      })
    },
    cardAboutSpacing(val) {
      this.photoBox.css({
        'margin-right': val*this.model.bl + 'px',
        'margin-left': val*this.model.bl + 'px',
      })
    },
    modelTopSpacing(val) {
      this.paper.css('padding-top', val*this.model.bl+'px')
    },
    modelBottomSpacing(val) {
      this.paper.css('padding-bottom', val*this.model.bl+'px')
    },
    modelLeftSpacing(val) {
      this.paper.css('padding-left', val*this.model.bl+'px')
    },
    modelRightSpacing(val) {
      this.paper.css('padding-right', val*this.model.bl+'px')
    },
    cardBorder(val) {
      if(val)
        this.guide.css('box-shadow', `0 0 1px 1px ${this.model.cardBorderColor}`)
      else 
        this.guide.css('box-shadow', `none`)
    },
    cardBorderColor(val) {
      if(this.model.cardBorder)
        this.guide.css('box-shadow', `0 0 1px 1px ${val}`)
      else 
        this.guide.css('box-shadow', `none`)
    },
    locatingHole(val) {
      // var width = this.model.locatingMargin * 4
      if(val){
        this.circular.css('display', 'flex')
        // this.model.width += width
        // this.paper.css('width', (this.model.width) * this.model.bl +'px')
      } else {
        this.circular.hide()
        // this.model.width -= width
        // this.paper.css('width', (this.model.width) * this.model.bl +'px')
      }
    },
    locatingMargin(val) {
      let px = (val - 5) * this.model.bl + 'px'
      console.log(px)
      $('.long-lt').css({'left': px, 'top': px})
      $('.long-rt').css({'right': px, 'top': px})
      $('.long-lb').css({'left': px, 'bottom': px})
      $('.long-rb').css({'right': px, 'bottom': px})
    },
    locatingDiameter(val) {
      let px = val * this.model.bl + 'px'
      $('.circular').css({'width': px, 'height': px})
    },

    // 计算可渲染图片数量
    calcQuantity() {
      let model = this.model, bl = this.model.bl
      // x轴可容纳数量
      let width = this.photoBox.outerWidth(true) / bl,
      height = this.photoBox.outerHeight(true) / bl
      console.log(width)
      let xN = Math.floor((model.width - model.leftSpacing - model.rightSpacing)/ width)
      // y轴可容纳数量
      let yN = Math.floor((model.height - model.topSpacing - model.bottomSpacing)/ height)

      if(String(yN * xN) == 'NaN') return

      // if(yN * xN == 3) return 
      localStorage.setItem('pageNum', yN * xN)
      this.$emit('showNum', yN * xN, model)
    }
  },
  mounted() {
    window.jq = $

    this.paper = $('.A4')
    this.photoBox = $('.typeImg')
    this.guide = $('.guide')
    this.circular = $('.long')
  },
  updated() {
    this.calcQuantity()
    this.paper = $('.A4')
    this.photoBox = $('.typeImg')
    this.guide = $('.guide')
    this.circular = $('.long')
  }
}
</script>

<style lang="less" scoped>
.toConfigure {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  
  .story {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .model-attr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    
    .single {
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 10px;

      .font img {
        width: 18px;
        height: 18px;
      }

      .label {
        width: 90px;
        text-align: right;
        margin-right: 10px;
      }

      .value {
        width: calc(100% - 100px);
        position: relative;

        .tips {
          position: absolute;
          top: 100%;
          right: 30px;
          color: red;
          font-size: 12px;
        }
      }
    }
  }

  .category {
    width: 100%;
    background-color: #9bb5d3;
    color: #fff;
    padding: 3px 10px;
    box-sizing: border-box;
    letter-spacing: 3px;
    margin-bottom: 10px;
  }
}
</style>