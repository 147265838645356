<template>
  <div class="showCrd">
    <div class="card" :style="{width: bl * model.width + 'px', height: bl * model.height + 'px'}"></div>

    <div class="mask" @mousedown="clickMask($event)" @mousewheel="onMouseWheel($event)" @DOMMouseScroll="onMouseWheel($event)">
      <i class="el-icon-circle-close" @mousedown.self='cancel'></i>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  props: ['model'],
  data() {
    return {
      bl: 3.78,
      y: 0,
      x: 0,
      s: 1
    }
  },
  methods: {
    // 关闭
    cancel() {
      this.$emit('close')
    },
    clickMask(ev) {
      let dom = ev.path[0],
      x = ev.offsetX, y = ev.offsetY

      dom.onmouseup = e => {
        let curX = e.offsetX - x, 
          curY = e.offsetY - y
        x = e.offsetX
        y = e.offsetY
        
        this.y -= Math.abs(curX) > 1 ? 180 : 0 
        
        $('.showCrd .card').css('transform', `rotatey(${this.y}deg) rotatex(${this.x}deg) scale(1.5)`);
      }
    },

    onMouseWheel(ev) {
      return 
      var ev = ev || window.event;
      var down = true; 
      down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
      if (down) {
        this.s = this.s <= 1 ? 1 : this.s - 0.1
      } else {
        this.s += 0.1
      }

      $('.card').css("transform", `rotatey(${this.y}deg) rotatex(${this.x}deg) scale(${this.s})`)
      if (ev.preventDefault) {
        // 阻止默认事件
        ev.preventDefault();
      }
      return false;
    }
  },

  mounted() {
    // 正面
    let positive = $('.dataInputModel').clone(true).
    css('background-size', '100% 100%').addClass('positive')

    // 反面
    let tos = $('.back-dataInputModel').clone(true).
    css('background-size', '100% 100%').addClass('tos')
    
    $('.card').append(positive)
    $('.card').append(tos)
  }
}
</script>

<style lang="less" scoped>
.showCrd {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    position: relative;
    transform: rotatey(0deg) scale(1.5);
    transform-style: preserve-3d;
  }
  .positive, .tos {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .el-icon-circle-close {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 35px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>

<style lang="less" >
.positive, .tos {
  position: absolute;
  top: 0;
  left: 0;
  transform: translatez(1px);
  // opacity: .9;
}

.tos {
  transform: translatez(-1px) rotatey(180deg);
}
</style>