<template>
  <div class="container aaa">
    <div class="log">
      <img src="../../assets/images/hzLogo.png" alt="" title="点击跳转会智科技官方网站" @click="linkOW">
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" :before-leave="tabBefore">
      <el-tab-pane label="样式设计" name="templateDesign" v-if="loginInfo.includes('templateMaking')">
        <MakeServer v-if="activeName == 'templateDesign'" ref="templateDesign"></MakeServer>
      </el-tab-pane>
      <el-tab-pane label="数据输入" name="dataInput" :disabled='!fileTitle || !modelId' v-if="loginInfo.includes('dataInput')">
        <DataInput v-if="activeName == 'dataInput'" ref="dataInput"></DataInput>
      </el-tab-pane>
      <el-tab-pane label="排版输出" name="typesetOutput" :disabled='(!cardPerson.length || !modelId) || !curCardModel.finalize' v-if="loginInfo.includes('templateOutput')">
        <Typesetting v-if="activeName == 'typesetOutput'" ref="typesetOutput"></Typesetting>
      </el-tab-pane>
      <el-tab-pane label="多样式拼版" name="multicard" v-if="loginInfo.includes('multicard')">
        <Multicard v-if="activeName == 'multicard'" ref="multicard"></Multicard>
      </el-tab-pane>
    </el-tabs>
    <div class="outlogin">
      <div class="date">{{ date }} {{ days }}</div>
      <div class="face">
        <img src="../../assets/images/hztLogo.png" alt="">

        <div class="hover">
          <div class="box">
            <div class="" @click="lookUserInfo = true">用户资料</div>
            <div class="" @click="editPass = true">修改密码</div>
            <div class="" @click="address_switch = true">地址管理</div>
            <div class="" @click="sendRecord_switch = true">发送记录</div>
            <div class="" @click="outLogin">退出登录</div>
          </div>
        </div>
      </div>
      <!-- <el-button size="small" @click="editPass = true">修改密码</el-button>
      <el-button size="small" @click="outLogin">退出登录</el-button> -->
    </div>

    <!-- 设置用户名密码 -->
    <el-dialog title="设置用户名密码" :visible.sync="isUse" width="600px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <div class="form">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          
          <el-form-item label="用户名" prop="loginName">
            <el-input v-model="ruleForm.loginName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
            <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="submitForm" size="small" round>确 定</el-button>
        <el-button v-preventReClick @click="isUse = false" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>

    <!-- 修改用户名密码 -->
    <el-dialog title="修改用户名密码" :visible.sync="editPass" width="600px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <div class="form" v-if="editPass">
        <el-form :model="editFrom" status-icon :rules="editRules" ref="formName" label-width="100px" class="demo-ruleForm">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="editFrom.phone" placeholder="手机号" prefix-icon="el-icon-mobile-phone"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="smsCode">
            <el-input v-model="editFrom.smsCode" placeholder="验证码" prefix-icon="el-icon-lock" @keyup.native.enter="login" >
              <el-button v-preventReClick slot="append" :disabled="retryCount > 0"
                @click="sendSmsCode" >{{ retryCount > 0 ? `${retryCount}S 后重试` : "获取验证码" }}</el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="password">
            <el-input v-model="editFrom.password" type="password" placeholder="新密码" prefix-icon="el-icon-lock"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="ecitUserPass" size="small" round>确 定</el-button>
        <el-button v-preventReClick @click="editPass = false" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>

    <!-- 用户资料 -->
    <el-dialog title="用户资料" :visible.sync="lookUserInfo" width="600px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <div class="form">
        <el-form status-icon ref="formName" label-width="100px" class="demo-ruleForm">
          <el-form-item label="版本名称" >
            {{userInfo.systemEditionName}}
          </el-form-item>
          <el-form-item label="公司名称" >
            {{ userInfo.companyName }}
          </el-form-item>
          <el-form-item label="姓名" >
            {{ userInfo.customerName }}
          </el-form-item>
          <el-form-item label="行业" >
            {{ userInfo.trade }}
          </el-form-item>
          <el-form-item label="电子邮箱" >
            {{ userInfo.email }}
          </el-form-item>
          <el-form-item label="手机号" >
            {{ userInfo.phone }}
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="lookUserInfo = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 地址管理 -->
    <el-dialog title="地址管理" :visible.sync="address_switch" width="1100px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <Address v-if="address_switch"></Address>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="address_switch = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 发送记录 -->
    <el-dialog title="发送记录" :visible.sync="sendRecord_switch" width="900px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <sendRecord v-if="sendRecord_switch"></sendRecord>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="sendRecord_switch = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// 处理颜色 hex转为cmyk
import { convertTo } from '@/plugins/hex_cmyk.js'
import { selfTime } from '@/plugins/plugins.js'

import Typesetting from '@/pages/Typesetting/typesetting.vue'
import MakeServer from '@/pages/MakeServer/makeServer.vue'
import DataInput from '../DataInput/dataInput.vue'
import Multicard from '../Multicard/multicard.vue'

import Address from './address/address.vue'
import sendRecord from './sendRecord/sendRecord.vue'

export default {
  components: {
    Typesetting,
    MakeServer,
    DataInput,
    Multicard,

    Address,
    sendRecord
  },
  data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };

      var validatePhone = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入手机号'));
        } else if (!(/(^1[3|2|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/.test(value))) {
          callback(new Error('请输入正确的手机号!'));
        } else {
          callback();
        }
      };
    return {
      date: '',
      days: '',

      activeName: 'templateDesign',
      activeArr: ['templateDesign'],
      userInfo: {},
      loginInfo: [],
      systemList: [],

      isUse: false,
      editPass: false, 
      lookUserInfo: false,
      address_switch: false,
      sendRecord_switch: false,
      retryCount: 0,

      ruleForm: {
        loginName: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        loginName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },

      editFrom: {
        phone: '',
        smsCode: '',
        password: ''
      },
      editRules: {
        phone: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },


    };
  },
  computed: {
    ...mapState(['modelId', 'cardPerson', 'curCardModel', 'fileTitle'])
  },
  watch: {
    editPass: function() {
      if(!val) {
        this.editFrom = {
          phone: '',
          smsCode: '',
          password: ''
        }
      }
    }
  },

  methods: {
    ...mapMutations(['updateFileTitle', 'setCurCardModel', 'updateModelId', 'updateCardPerson', 'updateCardTypeList', 'updateCurCardType']),
    handleClick(tab, event) {
      document.querySelector('.aaa .el-tabs__header').style.backgroundColor = 'transparent'
      this.activeArr.push(this.activeName)
      
      if(this.activeName == 'templateDesign') {
        setTimeout(() => {
          document.querySelector('.right-body').focus()
          if(this.curCardModel.finalize == 1) this.$refs.templateDesign.disableShade = false
        }, 500)
      } else if(this.activeName == 'dataInput' && this.activeArr[this.activeArr.length - 2] == 'templateDesign') {
        // 已定稿 不保存
        if(this.curCardModel && this.curCardModel.finalize === 1) return 

        this.role = true
        this.$refs.templateDesign.modelTitle = ''
        let { model, collection, cardTag } = this.$refs.templateDesign
        
        this.setCurCardModel({ model, collection})

        if(JSON.stringify(model) == localStorage.getItem('rule')) return 

        let background = {...model},
        elements = JSON.parse(JSON.stringify(collection))

        background.color = convertTo('hex', 'rgb', background.color)
        background.backColor = convertTo('hex', 'rgb', background.backColor)
        background.modelName = this.fileTitle
        background.id = this.modelId
        background.cardTag = cardTag
        for(let i = 0; i < elements.length; i++) {
          if(elements[i].eleType == 'text') {
            elements[i].color = convertTo('hex', 'rgb', elements[i].font.color)
            elements[i].fontSize = elements[i].font.size
            elements[i].center = elements[i].font.textAlign
            elements[i].fonts = elements[i].font.fonts

            delete elements[i].font
          } else {
            elements[i].radio = elements[i].border.radius

            delete elements[i].border
          }
          delete elements[i].id
          delete elements[i].border
          delete elements[i].boxshadow
        }

        delete background.Cover

        let obja = { background, elements}

        this.$http.post(this.API.generateCard('saveElementAndUser'), obja)
          .then((res) => {
            // let res = JSON.parse(body)
            if(res.code == '000') {
              this.updateModelId(res.data)
            } else {
              this.$message.error(res.msg)
            }
          })
      } else if(this.activeName == 'typesetOutput') {

      } if(this.activeName == 'multicard') {
        document.querySelector('.aaa .el-tabs__header').style.backgroundColor = '#fff'
      }

      return false
    },
    tabBefore(activeName, oldActiveName) {
      if(oldActiveName == 'multicard') {
        let multicard = this.$refs.multicard
        
        if(multicard.echoModel.length > 0) {
          this.$confirm('拼版还未结束，是否离开?', '提示', {
            closeOnPressEscape: false,
            closeOnClickModal: false,
            showClose: false,
            cancelButtonClass: 'btn_custom_cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.activeName = activeName
            multicard.removeCard(true)
            document.querySelector('.aaa .el-tabs__header').style.backgroundColor = 'transparent'
          }).catch(() => {})
          return false
        }
      }

    },
    typesetCom() {
      if(!this.curCardModel.finalize) {
        this.$message.info('此证卡文件未定稿，不能进行排版输出!')
        return 
      }

      if(!this.modelId) this.$message.info('请先设计证卡模板!')
      else if(!this.cardPerson.length) this.$message.info('请勾选人员!')
    },
    dataInputCom() {
      console.log(this.fileTitle)
      if(this.$refs.templateDesign.useModel) {
        this.$refs.templateDesign.saveAs()
        return false
      }
      if(!this.fileTitle || !this.modelId)
        this.$refs.templateDesign.fileTitle_child = true

      return false
    },
    linkOW() {
      let aDom = document.createElement('a')
      aDom.setAttribute('href', 'https://www.hzics.com/')
      aDom.setAttribute('target', '_blank')

      aDom.click()
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let obj = {
            password: this.ruleForm.pass,
            loginName: this.ruleForm.loginName
          }

          this.$http.post(this.API.initLoginNameAndPassword, obj)
            .then((res) => {
              // let res = JSON.parse(body)
              if(res.code == '000'){
                this.$message.success('设置成功!')
                localStorage.removeItem('isuse')
                this.isUse = false
              } else {
                this.$message.error(res.msg)
              }
            })
        }
      });
    },
    // 退出登录
    outLogin() {
      this.$confirm('是否退出登录?', '提示', {
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.do_submit();
        localStorage.removeItem('tokenCard');
        localStorage.removeItem('companyId')
        this.$router.push('/login')
      }).catch((err) => {
        console.log(err)
      })
    },
    ecitUserPass() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          let obj = {...this.editFrom}
          console.log(obj)
          this.$http.post(this.API.cardUpdate, obj) 
            .then((res) => {
              // let res = JSON.parse(body)
              console.log(res)
              if(res.code == '000') {
                this.$message.success('修改成功!')
                this.editPass = false
                this.editFrom = { smsCode: '', password: '' }
              } else {
                this.$message.error(res.msg)
              }
            })
        }
      });
    },
    do_submit() {
      this.updateModelId(null)
      this.updateFileTitle('')
      this.updateCardPerson([])

      // 处理数据
      let item = {
        cardTag: '',
        collection: [],
        model: {
          width: (104 / this.curSize).toFixed(0) * 1,
          height: (74 / this.curSize).toFixed(0) * 1,
          color: '#ffffff',
          image: '',
          backImage: '',
          backColor: '#ffffff',
          plate: 'oneSide'
        },
      }

      // 保存数据 vuex
      this.setCurCardModel(item)
    },
    sendSmsCode: function () {
      let _this = this;
      this.$http.get(this.API.updatePass(this.editFrom.phone))
        .then((res) => {
          if (res.code == "000") {
            _this.$message.success("验证短信已送！");
            this.setRetry();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          _this.$message.error("系统异常，请稍后重试！");
        });
    }, 
    setRetry: function () {
      if (this.retryCount > 0) {
        return;
      }
      let _this = this;
      _this.retryCount = 60;
      let handle = setInterval(() => {
        _this.retryCount--;
        if (_this.retryCount <= 0) {
          clearInterval(handle);
        }
      }, 1000);
    },
    // 获取公司信息
    getCustomer() {
      this.$http.post(this.API.getCustomer)
      .then((res) => {
        // let res = JSON.parse(body)
        if(res.code == '000'){
          if(!res.data.loginName) {
            this.init_child = true
          }
          localStorage.setItem('companyId', res.data.id)
          this.userInfo = res.data
          this.getCustomType()
        }
      })
    },
    // 获取公司权限
    findJurisdiction() {
      this.loginInfo = []
      this.$http.post(this.API.findJurisdiction)
      .then((res) => {
        console.log(res)

        if(res.code == '000'){
          this.loginInfo = res.data.map(its => its.menuUrl)
        }
      })
    },
    // 获取证卡类别
    getCardType() {
      this.$http.post(this.API.getAllCardTag) 
        .then(res => {
          if(res.code == '000') {
            let options = res.data.filter(its => its.isShow == '1' && its)
            this.updateCardTypeList(options)
          }
        })
    },
    // 获取版本列表
    getCustomType() {
      this.$http.post(this.API.getCustomType)
        .then((res) => {
          console.log(res)
          if(res.code == '000') {
            this.systemList = res.data.sort((cur, next) => cur.sort - next.sort)
            res.data.filter(its => {
              if(its.id == this.userInfo.systemEditionName) {
                this.userInfo.systemName = its.systemEditionName
              }
            })

            localStorage.setItem('loginInfo', JSON.stringify(this.userInfo))
          }
        })
    },

  },
  created() {
    let curSj = new Date().getTime()
    this.date = selfTime(curSj, false)
    switch(new Date().getDay()){
      case 1: 
          this.days = '星期一'; break
      case 2: 
          this.days = '星期二'; break
      case 3: 
          this.days = '星期三'; break
      case 4: 
          this.days = '星期四'; break
      case 5: 
          this.days = '星期五'; break
      case 6: 
          this.days = '星期六'; break
      case 0: 
          this.days = '星期日'; break
    }

    this.getCustomer()
    this.findJurisdiction()
    this.getCardType()

    setTimeout(() => {
      this.isUse = localStorage.getItem('isuse') ? true : false
    }, 500)
  },
  mounted() {
    setTimeout(() => {
      this.loginInfo.includes('dataInput') ? document.querySelector('#tab-dataInput').onclick = this.dataInputCom : null;
      this.loginInfo.includes('templateOutput') == 1 ? document.querySelector('#tab-typesetOutput').onclick = this.typesetCom : null;
    }, 1000)
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  box-sizing: border-box;
  // display: flex;

  .log {
    width: 100px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 999;
    text-align: left;
    
    img {
      width: calc(100% - 20px);
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .outlogin {
    width: 200px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 50px;

    .face {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: relative;
      
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
      }

      .hover {
        display: none;
        position: absolute;
        top: 99%;
        left: -10px;
        padding-top: 10px;
        box-sizing: border-box;

        .box {
          top: calc(100% + 10px);
          left: -10px;
          width: 100px;
          border-radius: 3px;
          box-shadow: 1px 1px 10px 1px #ccc;
          padding: 5px 0;
          box-sizing: border-box;
          background-color: #FFf;
          
          div {
            color: #333;
            font-size: 14px;
            width: 100%;
            text-align: center;
            padding: 5px 0;
            box-sizing: border-box;
            cursor: pointer;
            transition: background .3s;

            &:hover {
              background-color: #eee;
            }
          }
        }


        &:after {
          content: '';
          position: absolute;
          top: -10px;
          left: 20px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: #fff;
        }
      }

      &:hover .hover {
        display: block;
      }
    }
  }
}
</style>

<style lang='less'>
.aaa {
  .el-tabs, .el-tab-pane {
    height: 100%;
  }

  .el-tabs__content {
    height: calc(100% - 55px);
    box-sizing: border-box;
  }

  .el-tabs__header {
    padding-left: 120px;
    box-sizing: border-box;
  }
}
</style>