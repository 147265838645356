import { echo } from './echoModel.js'
import { handleStyle } from './handleStyle.js'
import $ from 'jquery'
import { mapState } from 'vuex'
import axios from 'axios'
// 处理颜色 hex转为cmyk
import { convertTo } from '@/plugins/hex_cmyk.js'
import {family} from './fontFamil.js'
import toConfigure from './toConfigure/toConfigure.vue'
import getModelPerson from './getModelPerson/getModelPerson.vue'
import cardFile from './cardFile/cardFile.vue'
import filterCard from './cardFile/filterCard.vue'
import COS from 'cos-js-sdk-v5'
import { Checkbox } from 'element-ui'

let load = null, time = null

export default {
  components: {
    toConfigure,
    getModelPerson,
    cardFile,
    filterCard
  },
  data() {
    return {
      id: null, 
      measurement: '1',
      options: [
        {value: '0.5', label: '50%'},
        {value: '0.75', label: '75%'},
        {value: '1', label: '100%'},
        {value: '1.5', label: '150%'},
        {value: '2', label: '200%'},
      ],
      model: {
        width: 0,
        height: 281
      },
      fileTitle: '',
      styleTitle: '',
      pageSize: 10,
      pageNum: 1,
      pageNums: 1,
      outputNum: 10,
      story: [],
      back: [],
      curModel: {
        bl: 3.78,
        width: 220, // 页宽度
        height: 307, // 页高度
        udSpacing: 0, // 卡片距离  上下间距
        aboutSpacing: 0, // 卡片距离  左右间距
        topSpacing: 5, // 页边距 上边距
        leftSpacing: 5, // 页边距 左边距
        bottomSpacing: 5, // 页边距 下边距
        rightSpacing: 5, // 页边距 右边距
        cardBorder: false,
        cardBorderColor: '#000',
        locatingHole: true,
        locatingMargin: 5,
        locatingDiameter: 3,
        dpi: 300,
        outputNum: 160
      },

      // 排版图片
      typeImg: [],
      varName: [],
      typeList: [],
      t: 'add',

      // 子组件开关
      multicard_child: false,
      styleTitle_child: false,
      is_lookBack: true,
      getModelPerson_child: false,
      chioceCard_child: false,
      filterCard_child: false,

      // 拼版模板
      echoModel: [],
      curEchoModel: {},

      // 初始化尺寸数据
      initSize: {},

      bindPersonMap: {}
    }
  },
  methods: {
    // 设置标题
    setTitle() {
      this.$prompt('设置标题', '提示', {
        closeOnPressEscape: false,
        closeOnClickModal: false,
        showCancelButton: false,
        showClose: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.fileTitle,
        type: 'warning',
        inputValidator: val => {
          if(!val.trim()) return '标题不能为空'

          return true
        }
      }).then(({value}) => {
         console.log(value)
         this.fileTitle = value.trim() || ''
      }).catch(() => {})
    },
    // 删除
    del(id, idx) {
      this.$confirm('是否删除该文件?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message.success('删除成功！')
        let {story, back} = this.bindPersonMap[id], 
        data = back || []
        
        // 删除dom
        if(this.is_lookBack) data = story || []
        for(let i = 0; i < data.length; i++) data[i].remove()
        // 删除总数据 this.story this.back
        let objArrLen = 0
        for(let i in this.bindPersonMap) {
          if(i === id) break

          objArrLen += this.bindPersonMap[i].story ? this.bindPersonMap[i].story.length : 0
        }
        this.story.splice(objArrLen, objArrLen + data.length)
        this.back.splice(objArrLen, objArrLen + data.length)
        
        // 删除列表中模板
        this.echoModel.splice(idx, 1)

        // 删除集合中模板
        delete this.bindPersonMap[id]
        
        console.log(this.echoModel)
        if(this.echoModel.length == 0) {
          this.bindPersonMap = {}
          this.initSize = {}
          this.curEchoModel = {}
          this.echoModel = []
          $('.self').remove()
        }
      })
    },

    // 清除卡样
    removeCard(bool = false) {
      if(bool) {
        this.bindPersonMap = {}
        this.initSize = {}
        this.curEchoModel = {}
        this.echoModel = []
        $('.self').remove()
        return 
      }
      this.$confirm('新建将情况当前拼版数据，是否继续?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.bindPersonMap = {}
        this.initSize = {}
        this.curEchoModel = {}
        this.echoModel = []
        $('.self').remove()
      })
    },
    // 选择卡样
    chioceCard() {
      this.chioceCard_child = true
    },
    // 证卡文件 - 确定
    determine() {
      let model = this.$refs.Model,
      modelList = model.modelList,
      that = this

      // 选中卡样
      let check = modelList.filter(its => its.select && its)

      if(check.length == 0) {
        this.$message.error('请选择卡样')
        return 
      } else {
        lx(check)
        function lx(arr, i = 0) {
          if(i != arr.length) {
            let row = check[i]
            that.fileEdit(row)
            lx(arr, i + 1)
          }
        }
      }
    },
    // 选中卡样
    fileEdit(row) {
      // if(!this.initSize.id) this.initSize = {...row}
      this.bindPersonMap[row.id] = {cardModel: {...row}}
      this.clickModel(row)

      this.model = {
        height: row.model.height * 3.78,
      }
    },
    // 选择尺寸
    changeSelect(val) {
      $('.A4').css('transform', `scale(${val})`)

      this.updateOvflow()
    },
    // 点击模板
    clickModel(its) {
      // if(!this.bindPersonMap[its.id]) 
      let copy, backCopy

      if($(`.mode .hzkj-${its.id}`).length == 0) {
        // 复制模板模型
        copy = $($('.typeModel')[0]).clone(true).addClass(`hzkj-${its.id}`)
        
        // 清空所有子节点
        copy.children().remove()
        $('.mode').append(copy)
        
        backCopy = $($('.back-typeModel')[0]).clone(true).addClass(`hzkj-back-${its.id}`)
        backCopy.children().remove()
        $('.mode').append(backCopy)
      } else {
        copy = $(`.mode .hzkj-${its.id}`)

        backCopy = $(`.mode .hzkj-back-${its.id}`)
      }
      
      // 复原模板
      let row = {...its, vue: this}
      var varName = echo(row, copy, backCopy)
      
      // 根据id保存变量
      this.bindPersonMap[its.id].varName = varName
      
      // this.curEchoModel = its

      // console.log(its)
      this.getProsonData(its)
      // this.getModelPerson_child = true
    },
    // 获取人员数据
    getProsonData(its){
      let obj = {
        contanUserIdArr: [],
        ifContanUserIdArr: false,
        queryConditionArr: this.queryConditionArr
      }, deparmentId = JSON.parse(localStorage.getItem('loginInfo')).companyId
      this.$http.post(this.API.conditionQuerys(deparmentId, 1, 9999, '', '', 2, 'card', its.id), obj)
        .then((res) => {
          if (res.code == "000") {
            let arr = []
            res.data.filter(item => {
              item.cardNum = item.cardNum == 0 ? 1 : item.cardNum
              for(let i = 0; i < item.cardNum; i++) arr.push(item)
            })
            this.bindPersonMap[its.id].person = arr
          } else {
          } 
          console.log(this.bindPersonMap)
          this.choiceBindPerson(its)
        })
    },

    // 双击
    doubleClick() {
      this.multicard_child = true
    },
    // 绑定人员 - 确定
    choiceBindPerson(its, bool = true) {
      let curModel = this.bindPersonMap[its.id]
      if(bool && curModel.person) this.patchworkAppend(its)

      // 获取同尺寸样卡
      let ids = this.echoModel.map(its => its.id)
      if(!ids.includes(its.id)) this.echoModel.push({...its})

      this.getModelPerson_child = false
      this.chioceCard_child = false
      this.filterCard_child = false
      
      // 第一个模板后弹出
      if(!this.fileTitle && this.echoModel.length == 1) this.setTitle()
    },
    // 拼版追加
    patchworkAppend(item) {
      let curModel = this.bindPersonMap[item.id]
      curModel.story = []
      curModel.back = []
      let arr = curModel.person, varName = curModel.varName

      for(let j = 0; j < arr.length; j++) {
        var html = $($(`.hzkj-${item.id}`)[0]).clone(true).addClass('self typeImg guide')
        let its = arr[j]
        for(let i of varName) {
          if(i == 'photoFileSaveName') {
            html.find('.'+i).css({
              'background-image': `url(${this.API.echoFace(its[i], item.id)})`,
              'background-repeat': 'no-repeat',
              'background-size': '100% 100%',
              'height': '100%',
            })
          } else {
            html.find('.'+i).text(its[i])
          }
        }

        curModel.story.push(html)
      }

      for(let j = 0; j < arr.length; j++) {
        var html = $($(`.hzkj-back-${item.id}`)[0]).clone(true).addClass('self typeImg guide')
        let its = arr[j]
        for(let i of varName) {
          if(i == 'photoFileSaveName') {
            html.find('.'+i).css({
              'background-image': `url(${this.API.echoFace(its[i], item.id)})`,
              'background-repeat': 'no-repeat',
              'background-size': '100% 100%',
              'height': '100%',
            })
          } else {
            html.find('.'+i).text(its[i])
          }
        }

        curModel.back.push(html)
      }
      
      // 同步数据
      this.story = []
      this.back = []
      for(let i in this.bindPersonMap) {
        let its = this.bindPersonMap[i]
        
        its.story && this.story.push(...its.story)
        its.back && this.back.push(...its.back)
      }
      
      // 正面，背面
      if(this.is_lookBack) this.test()
      else this.lookBack()
    },
    
    // 查看背面
    lookBack( bool = true) {
      let model = this.curModel, bl = model.bl, back = [...this.back],
      start = (this.pageNum - 1) * this.pageSize, end = this.pageSize * this.pageNum
 
      // x轴可容纳数量
      let width = $('.guide').outerWidth(true) / bl
      
      let xN = Math.floor((model.width - model.leftSpacing - model.rightSpacing)/ width)
      
      for(let i = start; xN > 1 && i < end; ) {
        var leng = Math.floor(xN / 2)
        for(let j = 0; j < leng; j++) {
          var temp = back[j + i]
          back[j + i] = back[i + xN - 1 - j]
          back[i + xN - 1 - j] = temp

          console.log(j + i, i + xN - 1 - j)
        }
        i += xN
      }
      // console.log(back.slice(start, end))
      this.is_lookBack = false
      $('.self').remove()
      // this.typeImg = this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum)
      console.log(start, end, back)
      $('.A4').append(...back.slice(start, end));
      $('.A4').css('justify-content', 'flex-end');

      bool && ([model.leftSpacing, model.rightSpacing] = [model.rightSpacing, model.leftSpacing])
      setTimeout(() => handleStyle(model), 100)
    },
    // 返回正面
    returnPositive () {
      this.is_lookBack = true

      $('.self').remove()
      $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
      $('.A4').css('justify-content', 'flex-start')

      let model = this.curModel;
      [model.leftSpacing, model.rightSpacing] = [model.rightSpacing, model.leftSpacing]
      setTimeout(() => handleStyle(model), 100)
    },
    // 上一页
    prvePage() {
      // this.is_lookBack = true
      this.pageNum --
      this.pageNums = this.pageNum

      if( !this.is_lookBack) this.lookBack(false) 
      else {
        $('.self').remove()
        // this.typeImg = this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum)
        $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
        $('.A4').css('justify-content', 'flex-start')
  
        setTimeout(() => {
          let photoBox = $('.typeImg'),
          guide = $('.guide'),
          curModel = this.curModel
  
          photoBox.css({
            'margin-right': curModel.aboutSpacing * curModel.bl + 'px',
            'margin-left': curModel.aboutSpacing * curModel.bl + 'px',
            'margin-top': curModel.udSpacing * curModel.bl + 'px',
            'margin-bottom': curModel.udSpacing * curModel.bl + 'px',
          })
  
          guide.css({
            'box-shadow': curModel.cardBorder ? `0 0 1px 1px ${curModel.cardBorderColor}` : 'none'
          })
        }, 10)
      }
    },
    // 下一页
    nextPage() {
      // this.is_lookBack = true
      this.pageNum ++
      this.pageNums = this.pageNum
      if( !this.is_lookBack) this.lookBack(false) 
      else {
        $('.self').remove()
        // this.typeImg = this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum)
        $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
        $('.A4').css('justify-content', 'flex-start')
  
        setTimeout(() => {
          let photoBox = $('.typeImg'),
          guide = $('.guide'),
          curModel = this.curModel
  
          photoBox.css({
            'margin-right': curModel.aboutSpacing * curModel.bl + 'px',
            'margin-left': curModel.aboutSpacing * curModel.bl + 'px',
            'margin-top': curModel.udSpacing * curModel.bl + 'px',
            'margin-bottom': curModel.udSpacing * curModel.bl + 'px',
          })
  
          guide.css({
            'box-shadow': curModel.cardBorder ? `0 0 1px 1px ${curModel.cardBorderColor}` : 'none'
          })
        }, 10)
      }
    },
    searchBtn() {
      let maxPage = Math.ceil(this.story.length / this.pageSize)
      if(this.pageNums < 1) {
        this.pageNums = 1
      } else if(this.pageNums > maxPage) {
        this.pageNums = maxPage
      }

      if(this.pageNums != this.pageNum) {
        
        this.pageNum = this.pageNums
        if( !this.is_lookBack) this.lookBack(false) 
        else {
          $('.self').remove()
          $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
          $('.A4').css('justify-content', 'flex-start')
  
          setTimeout(() => {
            let photoBox = $('.typeImg'),
            guide = $('.guide'),
            curModel = this.curModel
  
            photoBox.css({
              'margin-right': curModel.aboutSpacing * curModel.bl + 'px',
              'margin-left': curModel.aboutSpacing * curModel.bl + 'px',
              'margin-top': curModel.udSpacing * curModel.bl + 'px',
              'margin-bottom': curModel.udSpacing * curModel.bl + 'px',
            })
  
            guide.css({
              'box-shadow': curModel.cardBorder ? `0 0 1px 1px ${curModel.cardBorderColor}` : 'none'
            })
          }, 10)
        }
      }
    },

    // 获取展示数量
    showNum(num, model) {
      this.typeImg = this.story.slice((this.pageNum - 1) * num, num * this.pageNum)
      setTimeout(() => handleStyle(model), 100)

      this.curModel = model
      this.pageSize = num
      // 保存缓存
      localStorage.setItem('style', JSON.stringify(model))
      console.log('展示数量: ' + num, '总页: '+ Math.ceil(this.story.length / num), '当前页: ' + this.pageNum)
      if(Math.ceil(this.story.length / num) < this.pageNum) {
        this.pageNum = 1
      }
      
      if( !this.is_lookBack) this.lookBack(false) 
      else {
        $('.A4 .self').remove()
        $('.A4').append(...this.story.slice((this.pageNum - 1) * num, num * this.pageNum))
      }
    },
    // 子组件关闭
    cancel() {
      this.multicard_child = false
    },
    // worker
    test() {
      // this.story = []
      $('.A4 .self').remove()

      console.log(`%c 总人数: ${this.story.length}`, 'color: skyblue; font-size: 30px;')

      this.pageSize = this.calcQuantity()
      
      $('.A4').append(...this.story.slice((this.pageNum - 1), this.pageSize))
      setTimeout(() => handleStyle(this.curModel), 100)
    },
    // 计算可渲染图片数量
    calcQuantity() {
      let model = this.curModel, bl = this.curModel.bl
      // x轴可容纳数量
      let width = $(this.story[0]).outerWidth() / bl + model.aboutSpacing * 2,
      height = $(this.story[0]).outerHeight() / bl + model.udSpacing * 2
      
      let xN = Math.floor((model.width - model.leftSpacing - model.rightSpacing) / width)
      // y轴可容纳数量
      let yN = Math.floor((model.height - model.topSpacing - model.bottomSpacing) / height)
      if(String(yN * xN) == 'NaN') return
      
      return yN * xN
    },

    // 临时图片
    getObjectURL(file) {
      var url = null ;
      if (window.createObjectURL!=undefined) { // basic
        url = window.createObjectURL(file) ;
      } else if (window.URL!=undefined) { // mozilla(firefox)
        url = window.URL.createObjectURL(file) ;
      } else if (window.webkitURL!=undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file) ;
      }
      return url ;
    },
    // 选择排版
    chioceType(row) {
      this.curSelect = {...row}
      this.curModel = JSON.parse(row.pdfJson)
      // 保存缓存
      localStorage.setItem('style', row.pdfJson)
      this.id = row.id
      this.styleTitle = row.modelName

      let width = $('.typeImg').width()
      $('.self').remove()
      
      if(String(width) !== 'NaN') {
        this.pageSize = this.calcQuantity()
        console.log(this.pageSize)
        $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
      }

      this.updateOvflow()
    },
    updateOvflow() {
      setTimeout(() => {
        handleStyle(this.curModel)
        let A4 = $('.A4').outerWidth() * this.measurement * 1,
        whiteBoard = $('.white-board').outerWidth()
        console.log(A4, whiteBoard)
        if(A4 > whiteBoard) {
          $('.A4').css({'left': '0','transform-origin': 'left top'})
          $('.white-board').css({'overflow-x': 'auto',})
        } else {
          $('.A4').css({'left': 'auto','transform-origin': 'center top'})
          $('.white-board').css({'overflow-x': 'hidden',})
        }
      }, 100)
    },
    delectRow(its) {
      this.$confirm('是否删除该排版?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.API.typedel(its.id))
          .then((res) => {
            // let res = JSON.parse(body)
            if(res.code == '000') {
              this.$message.success('删除成功!')
              this.getTypeset()
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },

    transition(t) {
      this.t = t
      if(t == 'add') {
        this.styleTitle = ''
      }
      this.styleTitle_child = true
    },

    // 保存排版样式
    save() {
      if(!this.styleTitle){ 
        this.styleTitle_child = true
        return 
      } else this.styleTitle_child = false
      
      let model = {...this.curModel}
      if(!this.is_lookBack) ([model.leftSpacing, model.rightSpacing] = [model.rightSpacing, model.leftSpacing])
      let obj = {
        modelName: this.styleTitle,
        pdfJson: JSON.stringify(model)
      }, 
      url = 'typeadd'
      
      if(this.t == 'edit') {
        obj.CreateTime = this.curSelect.CreateTime
        obj.companyId = this.curSelect.companyId
        obj.id = this.id
        url = 'typeSave'
      }

      this.$http.post(this.API.typeadd(url), obj)
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000') {
            this.$message.success('添加成功!')
            this.multicard_child = false
            this.getTypeset()
          } else {
            this.$message.error(res.msg)
          }
        })
    },

    generateCard() {
      this.$confirm('是否导出PDF文件?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let cardMap = JSON.parse(JSON.stringify(this.bindPersonMap)),
        card = [], pdf = {personIds: [], downPdf: this.paiban()},
        psersons = [], termination = []
        
        for(let i in cardMap) {
          let its = cardMap[i]
          if(!its.person) continue;
          
          let {background, dpi, elements, personIds} = this.handleData(its)
          psersons.push(...personIds)
          card.push({background, dpi, elements})
        }
        
        load = this.$loading({
          lock: true,
          text: '正在输出, 请等待...',
          customClass: 'loding-size',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        })

        // 分批下载pdf
        let leng = Math.ceil(psersons.length / this.curModel.outputNum), that = this
        lx()
        function lx(i = 0) {
          if(leng == i) {
          } else {
            pdf.downPdf.pdfPage = i + 1
            pdf.personIds = psersons.slice(i * that.curModel.outputNum, (i + 1) * that.curModel.outputNum)
            var str = JSON.stringify({card, pdf}, false, 2),
            file = new File([str], 'phone.txt', { type:  "text/plain;charset=utf-8"  })
    
            that.uploadDoc(file, leng, termination)
            setTimeout(() => {
              lx(i + 1)
            }, 3000)
          }
        }
      })

    },
    handleData(row) {
      console.log(row)
      var { collection, model, id } = JSON.parse(JSON.stringify(row.cardModel)),
      filter = ['zIndex', 'fontSize', 'opacity', ''],
      background = {...model},
      elements = collection,
      personIds = row.person.map(i => i.id)
      
      background.color = convertTo('hex', 'rgb', background.color)
      background.backColor = convertTo('hex', 'rgb', background.backColor)
      background.id = id
      background.modelName = row.cardModel.modelName
      delete background.Cover
  
      background.width = (11.81 * background.width).toFixed(0) * 1
      background.height = (11.81 * background.height).toFixed(0) * 1
  
      for(let i = 0; i < elements.length; i++) {
        if(elements[i].eleType == 'text') {
          elements[i].color = convertTo('hex', 'rgb', elements[i].font.color)
          elements[i].fontSize = elements[i].font.size
          elements[i].center = elements[i].font.textAlign
          elements[i].fonts = elements[i].font.fonts
        } else {
          elements[i].radio = elements[i].border.radius
        }
        delete elements[i].id
        delete elements[i].font
        delete elements[i].border
        delete elements[i].boxshadow
  
        for(let j in elements[i]) {
          if(typeof elements[i][j] == 'number' && !filter.includes(j))
            elements[i][j] = (11.81 * elements[i][j]).toFixed(0) * 1
        }
      }
  
      let obja = { background, dpi: this.curModel.dpi, elements, personIds}

      return obja
    },

    // 导出pdf
    paiban() {
      let downPdf = {...this.curModel}
      if(!this.is_lookBack) ([downPdf.leftSpacing, downPdf.rightSpacing] = [downPdf.rightSpacing, downPdf.leftSpacing])

      downPdf.frame = downPdf.cardBorder ? 1 : 0
      downPdf.locatingHole = downPdf.locatingHole ? 1 : 0
      downPdf.locatingHolex = downPdf.locatingHoley = downPdf.locatingMargin
      downPdf.locatingHoler = downPdf.locatingDiameter
      downPdf.personNum = downPdf.outputNum * 1
      downPdf.cardName = this.fileTitle
      downPdf.pdfType = 'patchwork'

      downPdf.imageWidh = ($(this.story[0]).width() / downPdf.bl).toFixed(0) * 1
      downPdf.imageHeight = ($(this.story[0]).height() / downPdf.bl).toFixed(0) * 1

      delete downPdf.cardBorder
      delete downPdf.cardBorderColor
      delete downPdf.locatingMargin
      delete downPdf.locatingDiameter
      delete downPdf.bl
      delete downPdf.outputNum

      return downPdf
    },
    uuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }, 
    // 获取上传文档签名
    getDocSignature(options, callback) {
      const that = this
      $.ajax({
        url: this.API.uploadSizeMax, // 获取客户端上传签名的 URL
        type: 'post',
        headers: {
          token: localStorage.getItem('tokenCard')
        },
        dataType: 'json',
        success(result) { // result 是派发签名服务器的回包
          if (result.code === '000') {
            const fileKey = result.data
            console.log(fileKey)
            callback({
              TmpSecretId: fileKey.TmpSecretId,
              TmpSecretKey: fileKey.TmpSecretKey,
              XCosSecurityToken: fileKey.Token,
              ExpiredTime: result.time,
            })
          } else {
            // 获取秘钥失败的相关操作
          }
        }
      })
    },
    uploadDoc(fileObj, leng, termination) { // 调取文档上传相关接口
      console.log(fileObj.name.substr(fileObj.name.length - 4))
      let suffix = '.txt',
      companyId = JSON.parse(localStorage.getItem('loginInfo')).companyId,
      dir = 'cardFile/' + companyId + '/' + new Date().getTime() + suffix
      
      this.cos.putObject({
        Bucket: 'hzcard-1305256445',  // 桶的名字，命名规则为{name}-{appid} ，此处填写的存储桶名称必须为此格式
        Region: 'ap-guangzhou',   //Bucket所在的区域
        Key: dir, // 文件地址: 公司id + 随机id
        StorageClass: 'STANDARD',
        Body: fileObj, // 文件的信息
      }, (err, data) => {
        console.log(err)
        if (!err && data) {
          console.log(data)
          // 没有结果
          axios.post(this.API.makeCardToPdf(dir), {},{
            headers: {
              token: localStorage.getItem('tokenCard')
            }
          }).then(({data}) => {
            let res = data
            console.log(res)
            if(res.code == '000') {
              let arr = res.data
              for(let i = 0; i < arr.length; i++) {
                var a = document.createElement('a')
                a.setAttribute('target', '_blank')
                a.setAttribute('download', `test-${i+1}.pdf`)
                a.href = `https://hzcard-1305256445.cos.ap-guangzhou.myqcloud.com/${arr[i]}`
                a.click()
              }
              
            } else {
              this.$message.error(res.msg)
            }

            termination.push(true)
            if(termination.length == leng) {
              load.close()
              load = null
            }
          })
        } else {
          this.$message.error('上传失败')
        }
      })
    },

    getTypeset() {
      this.$http.post(this.API.typeselect)
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000') {
            this.typeList = res.data
          }
        })
    }
  },
  computed: {
    ...mapState(['cardPerson', 'modelId'])
  },
  created() {
    this.getTypeset()
    let style = JSON.parse(localStorage.getItem('style')) || null

    style ? this.curModel = style : null
    this.updateOvflow()
    // this.pageSize = localStorage.getItem('pageNum') || 10
  },
  mounted() {
    this.model = {
      width: $('#model').width(),
      height: $('#model').height(),
    }
    
    // 初始化上传文档实例
    this.cos = new COS({ 
      getAuthorization: this.getDocSignature
    })

    // 分10个进程
    // this.worker = this.$worker.create([
    //   { message: 'img01', func: data => data},
    //   { message: 'img02', func: data => data},
    //   { message: 'img03', func: data => data},
    //   { message: 'img04', func: data => data},
    //   { message: 'img05', func: data => data},
    //   { message: 'img06', func: data => data},
    //   { message: 'img07', func: data => data},
    //   { message: 'img08', func: data => data},
    // ])
  }
}