import { mapState } from 'vuex'

export default {
  props: ['personId', 'tableCate', 'varName', 'modelId_'],
  components: {
  },
  data() {
    return {
      user: {
        cardNum: 1
      },
      cardNum: 0
    }
  },
  methods: {
    // 上传头像
    upload(){
      let file = this.$refs.avatar, files
      files = file.files[0]
      
      this.fileUpload(files, 'HeadFile', res => {
        if(res.code == '000'){
          this.$message.success('上传成功！')
          this.user.photoFileSaveName = res.data.saveFileName
        } else {
          this.$message.error(res.msg)
        }

        file.value = ''
      }, this.modelId)
    },

    // 获取人员数据
    getPersonData(){
      let id = this.personId
      this.$http.post(this.API.singleClomnData(id, this.modelId))
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000'){
            this.user = res.data

            this.cardNum = this.user.cardNum * 1
          }
        })
    }

  },
  watch: {
    cardNum: function(val) {
      this.user.cardNum = val
    }
  },
  computed: {
    ...mapState(['modelId'])
  },
  mounted() {
    this.getPersonData()
  }
}