<template>
    <div class="addperson">

      <el-form ref="userForm" class="userForm" :model="user" label-width="100px">
        <el-form-item label="头像: " v-if="varName.includes('photoFileSaveName')">
          <div class="userImage"> 
            <div class="avatar-uploader">
              <img v-if="user.photoFileSaveName" :src="API.echoFace(user.photoFileSaveName, (modelId || modelId_))" class="authsrc" id="addUserImg" alt="">

              <div class="tip" v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>

              <!-- 虚拟 标签 -->
              <input type="file" name="" id="avatar" ref='avatar' @change="upload">
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="its.label" v-for="(its, idx) in tableCate" :key="idx">
          <el-input size='small' :placeholder="`请输入${its.label}`" v-model="user[its.props]"></el-input>
        </el-form-item>
        <el-form-item label="制作数量" v-if="varName.length > 0">
          <!-- <el-input v-model="user.cardNum" oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input> -->
          <el-input-number v-model="user.cardNum" :min="1" ></el-input-number>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
import editPeroson from './editPeroson.js'

export default editPeroson
</script>

<style scoped lang='less'>
.full {
  width: 100% !important;
}

.addperson {
  .userForm {
    height: calc(100% - 72px);
    overflow-y: auto;
    padding-right: 20px;

    .face-box {
      width: 80px;
    }
  }

  .authsrc {
    width: 100%;
    height: 100%;
    border-radius: 500px;
  }

	.userImage {
		width: 140px;
		height: 100px;
		// position: absolute;
		right: 0;
    z-index: 90;
    display: flex;
    justify-content: center;
    // transform: scale(.8);
    transform-origin: top center;

		.avatar-uploader {
      width: 100px;
      height: 100px;
      position: relative;
      // display: flex;
      justify-content: center;
      align-content: center;
      border: 1px dashed #ccc;
      border-radius: 500px;
      box-sizing: border-box;
      cursor: pointer;

      #avatar {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        cursor: pointer;
      }

			.tip {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
        box-sizing: border-box;
			}
		}
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 0.375rem;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 1.75rem;
		color: #8c939d;
		text-align: center;
	}

	.avatar {
		width: 100%;
		height: 100%;
		display: block;
  }
}

</style>