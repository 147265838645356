<template>
  <div class="dataInput">
    <div class="echo-model" @dblclick='openCard'>
      <!-- 模子 -->
      <div class="dataInputModel" id="model">
        <div class="templateOS xcopy">
          <div class="cte"></div>
          <div class="rightBottom"></div>
        </div>
      </div>

      <!-- 模子 -->
      <div class="back-dataInputModel" id="model" v-if="curCardModel.model.plate === 'twoSided'">
      </div>
    </div>

    <!-- 部门 -->
    <div class="operat"> 
      <!-- tree -->
      <div class="list-tree">
        <el-tree
          :data="data"
          ref='person-tree'
          :props="treeProps"
          node-key="id"
          :expand-on-click-node="false"
          :highlight-current="true"
          @node-click='treeClick'
          default-expand-all
          :render-content="renderContent">
        </el-tree>
      </div>
    </div>

    <!-- 人员数据 -->
    <div class="person">
      <div class="func">
        <div>
          <el-dropdown trigger="click" @command="addPersonL" class="right-10px" placement='bottom-start'>
            <el-button v-preventReClick type="warning" size="small">
              <div class="cont">
                <span >数据管理</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a" style="width: 110px">数据添加</el-dropdown-item>
              <el-dropdown-item command="b" style="width: 110px">excel导入数据</el-dropdown-item>
              <el-dropdown-item command="e" style="width: 110px">会议数据导入</el-dropdown-item>
              <el-dropdown-item command="f" style="width: 110px">重名数据</el-dropdown-item>
              <el-dropdown-item command="g" style="width: 110px">导出查询数据</el-dropdown-item>
              <el-dropdown-item command="h" style="width: 110px">导出Excel日志</el-dropdown-item>
              <el-dropdown-item command="c" style="width: 110px">删除勾选数据</el-dropdown-item>
              <el-dropdown-item command="d" style="width: 110px">删除全部数据</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown trigger="click" @command="handleCommand" class="right-10px" placement='bottom-start'>
            <el-button v-preventReClick type="warning" size="small">
              <div class="cont">
                <span >相片管理</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a" style="width: 110px">上传相片</el-dropdown-item>
              <el-dropdown-item command="b" style="width: 110px">相片匹配</el-dropdown-item>
              <el-dropdown-item command="c" style="width: 110px">无相片数据</el-dropdown-item>
              <!-- <el-dropdown-item command="d">导出全部相片</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown> 
          <el-input size="small" placeholder="请输入数据名称" v-model="searchKey" @keyup.native.enter="searchBtn" style="width: 300px">
            <el-button v-preventReClick slot="append" icon="el-icon-search" @click="searchBtn"></el-button>
          </el-input>
        </div>

        <el-button v-preventReClick @click="sendCard" v-if="curCardModel.finalize == 1" class="edit" size='small'>发 送</el-button>

      </div>

      <!-- 人员表格 -->
      <div class="table">
        <el-table ref="singleTable" @selection-change="batchDel" highlight-current-row @current-change="handleCurrentChange"
          :data="tableData" border :height="height">
          <el-table-column :show-overflow-tooltip="true" align="center" :resizable='false' type="selection" width="50"></el-table-column>
          <el-table-column :show-overflow-tooltip="true" prop="index" width="50" label="序号" align="center" :resizable="false"></el-table-column>
          <el-table-column :show-overflow-tooltip="true" :resizable="false" align="center" label="头像" width="80" v-if="varName.includes('photoFileSaveName')">
            <template slot-scope="scope">
              <img v-lazy="`${ API.echoFace(scope.row.photoFileSaveName, modelId) }`" class="avatar" alt="">
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" :prop="item.props" :label="item.label" :width="item.width"
            v-for="(item, idx) in tableCate" :key="idx"
            align="center" :resizable="false">
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" :resizable="false" align="center" label="操作" width="100">
            <template slot-scope="scope">
              <el-button v-preventReClick @click="updateUser(scope.row)" class="edit" round size='small'>编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagin">
        <el-checkbox v-model="allPerson" @change="selectAll">全选</el-checkbox>

        <el-pagination
        background
        @size-change="sizeChange"
        @current-change="curChange"
        :current-page="pageNum"
        :page-sizes="[20, 50, 100, 300, 500]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <div></div>
      </div>
    </div>

    <!-- 添加人员 -->
    <el-dialog title="添加数据" :visible.sync="addPeroson_child" width="20%" center :before-close='cancel'
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <AddPerson ref="AddPerson" :varName='varName' :tableCate='tableCate' v-if="addPeroson_child" ></AddPerson>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="submitForm()" size="small" round>添 加</el-button>
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>

    <!-- 导入数据 -->
    <el-dialog title="Excel导入数据" :visible.sync="importPeroson_child" width="35%" center :before-close='cancel'
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <ExcelImportPeroson :modelId='modelId' ref="ExcelImportPeroson" v-if="importPeroson_child" @close='closeComponent'></ExcelImportPeroson>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 修改数据 -->
    <el-dialog title="修改数据" :visible.sync="editPeroson_child" width="20%" center :before-close='cancel'
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <EditPeroson ref="EditPerson" :personId='editPersonId' :varName='varName' :tableCate='tableCate' v-if="editPeroson_child"></EditPeroson>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="editSave()" size="small" round>保 存</el-button>
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>

    <!-- 条件组 -->
    <el-dialog title="条件组" :visible.sync="condi_child" width="60%" center append-to-body :before-close="close"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <conditionGroup ref="conditionGroup" v-if="condi_child"></conditionGroup>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="close" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 添加部门 -->
    <el-dialog title="部门" :visible.sync="department_child" width="60%" center append-to-body :before-close="close"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <addDepartment ref="addDepartment" @addQuery='doAddDepart' :departType='departType' :curDepar='curDepar' :bool='departBool' @editAddDepart='editAddDepart' v-if="department_child"></addDepartment>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="close" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 会议导入 -->
    <el-dialog title="会议导入数据" :visible.sync="meetingInport_child" width="60%" center append-to-body :before-close="close"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <meetingInport ref="meetingInport" v-if="meetingInport_child"></meetingInport>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="importPerson()" size="small" round>导 入</el-button>
        <el-button v-preventReClick @click="close" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 上传相片 -->
    <el-dialog title="上传相片" :visible.sync="updatePhoto_child" width="20%" center :before-close='cancel'
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <UpdatePhoto ref="UpdatePhoto" v-if="updatePhoto_child" @setShowNum='setShowNum' @close='cancel'></UpdatePhoto>
      <div class="dialog-btn padding-0">
        <div v-if="showNum != 0" class='tips-num' style="margin-right: 10px">一共上传{{ showNum }}张</div>
        <el-button v-preventReClick size="small" type="primary" v-if="showNum != 0" round @click="imgUploadAll($event, 0)">提 交</el-button>
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 无相片匹配 -->
    <el-dialog title="照片匹配" :visible.sync="noPhotos_child" width="60%" center :before-close='cancel'
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <NoPhotos :modelId='modelId' ref="NoPhotos" v-if="noPhotos_child"></NoPhotos>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="relation()" size="small" round>关联相片</el-button>
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 展示 -->
    <ShowCard v-if="curCardModel.model.plate === 'twoSided' && showCard_child"
      :model='curCardModel.model' @close='showCard_child = false'
    ></ShowCard>

    <!-- 选择地址 -->
    <el-dialog title="收货地址" :visible.sync="sendCrd_child" width="1100px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <div v-if="sendCrd_child" class="choice-address">
        <div class="address-region">
          <el-button size="small" @click="addressAdmin"> 地址管理 </el-button>
        </div>
        <el-table ref="singleTable" empty-text="暂无地址数据" @row-click="addressRow"
          :data="addresslist" border :height="400">
          <el-table-column :show-overflow-tooltip="true" align="center" :resizable='false' label="" width='50'>
            <template slot-scope="scope">
              <div :class="['radio', scope.row.select && 'select']"></div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" :prop="item.props" :label="item.label" :width="item.width"
            v-for="(item, idx) in addressCate" :key="idx"
            align="center" :resizable="false">
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" align="center" :resizable='false' label="地址" width=''>
            <template slot-scope="scope">
              <div class="">
                <div>{{ scope.row.region }}</div>
                <div>{{ scope.row.detailedAddress }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="sendCardBtn" size="small" round>发 送</el-button>
        <el-button v-preventReClick @click="sendCrd_child = false" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 地址管理 -->
    <el-dialog title="地址管理" :visible.sync="address_switch" width="1100px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <Address v-if="address_switch"></Address>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="cancelAddress" size="small" type="danger" round>关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery';

import { exportToExcel } from '@/plugins/plugins.js'
import { convertTo, rgbaNum } from '@/plugins/hex_cmyk.js'
import { echo, attrList } from './echoModel.js'
import { mapState, mapMutations } from 'vuex'
import AddPerson from './addPeroson/addPeroson.vue' // 添加人员
import ExcelImportPeroson from './excelImportPerson/excelImportPerson.vue' // 添加人员
import EditPeroson from './editPeroson/editPeroson.vue' // 修改人员
import addDepartment from './addDepartment/addDepartment.vue' // 条件组
import conditionGroup from './conditionGroup/conditionGroup.vue' // 条件组
import meetingInport from './meetingInport/meetingInport.vue' // 条件组
import UpdatePhoto from './updatePhoto/updatePhoto.vue' // 上传图片
import NoPhotos from './noPhotos/noPhotos.vue' // 人员无照片匹配
import ShowCard from './showCard/showCard.vue' // 展示合并卡片

import Address from '../Container/address/address.vue' // 地址管理

export default {
  components: {
    AddPerson,
    ExcelImportPeroson,
    EditPeroson,
    addDepartment,
    conditionGroup,
    meetingInport,
    UpdatePhoto,
    NoPhotos,
    ShowCard,
    Address
  },
  data() {
    return {
      model: {
        width: 0,
        height: 281
      },
      collection: [],

      varName: [],

      // tree
      data: [],
      treeProps: {
        children: 'children',
        label: 'departmentName'
      },

      // table
      height: null,
      tableData: [],
      tableCate: [
        // {props: 'userName', label: '姓名', width: ''},
        // {props: 'phone', label: '手机号', width: ''},
        // {props: 'departmentName', label: '部门', width: ''},
        // {props: 'characterId', label: '角色', width: ''},
        // {props: 'attribute1', label: '组别', width: ''},
      ],

      searchKey: '',

      // 获取人员参数 
      intefaceType: 'card', // 'card'非部门查询， cardDeparment部门查询
      photoFlag: 2, // 0有头像 1 无头像人员 2 全部
      externalCode: '', // '' 公司下, 1 外部, 0 内部
      deparmentId: '', // 部门id
      departBool: true,
      curDepar: {
        queryBuilderName: '',
        queryConditionList: []
      },

      // 勾选后数据
      batchdata: [],
      allPerson: false, // 人员全选

      // 条件组数据
      condiData: [],
      queryConditionArr: [],
      tjgroup: '条件组查询', 

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 100,

      showNum: 0,

      // 地址管理
      addresslist: [],
      addressCate: [
        {props: 'name', label: '姓名', width: '120'},
        {props: 'mobile', label: '手机号', width: '100'},
        {props: 'companyName', label: '公司名称', width: '160'},
      ],

      // 各子组件开关
      addPeroson_child: false,
      importPeroson_child: false,
      editPeroson_child: false,
      condi_child: false, 
      department_child: false,
      meetingInport_child: false,
      updatePhoto_child: false,
      noPhotos_child: false,
      showCard_child: false,
      sendCrd_child: false,
      address_switch: false
    }
  },
  methods: {
    ...mapMutations(['updateCardPerson']),
    // 地址管理
    addressAdmin() {
      this.address_switch = true
    },
    cancelAddress() {
      this.address_switch = false
      this.getAddressData()
    },

    // 发送卡片
    sendCard() {
      let batchdata = this.batchdata

      if(batchdata.length == 0) {
        this.$message.error('请勾选人员')
        return 
      }

      this.sendCrd_child = true
      this.getAddressData()
    },
    addressRow(row) {
      console.log(row)
      this.addresslist.filter(its => its.id == row.id ? its.select = true : its.select = false)
    },
    sendCardBtn() {
      let addressId = this.addresslist.filter(its => its.select && its)
      console.log(this.addresslist, addressId)
      if(!addressId.length) {
        this.$message.error('请选择地址!')
        return 
      }
      
      let obj = {
        ids: this.batchdata.map(its => its.id),
        cardId: this.modelId,
        addressId: addressId[0].id
      }

      this.$http.post(this.API.sendCardDataToAdmin, obj)
        .then(res => {
          if(res.code == '000') {
            this.$message.success('发送成功!')
            this.sendCrd_child = false
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 打开展示界面
    openCard() {
      console.log(this.curCardModel.model.plate)
      if(this.curCardModel.model.plate === 'twoSided')
      this.showCard_child = true
    },

    // 选中表格 row
    handleCurrentChange(row) {
      if(!row) return 
      let varName = this.varName

      for(let i = 0; i < varName.length; i++) {
        if(varName[i] == 'photoFileSaveName' && row[varName[i]]){
          console.log(this.API.echoFace(row.photoFileSaveName, 'HeadFile'))
          $('#model .'+varName[i]).css({
            'background-image': `url(${this.API.echoFace(row.photoFileSaveName, this.modelId, 'HeadFile')})`,
            'background-size': '100% 100%'
          })
        } else {
          $('#model .'+varName[i]).text(row[varName[i]])
        }
      }
    },

    // tree - 树结构
    renderContent(h, { node, data, store }) {
      let html;
      // // console.log(node,node.level)
      if(node.level == 1){
          html = (<span class="custom-tree-node" >
          <span class="tree-title"> {node.label} </span> <span class="node-tool-bar" >
            {
              <el-button v-preventReClick type="text" class="el-icon-circle-plus-outline" title="添加"
              on-click={(e) => this.append(data, node,  e)} > </el-button>
            }
            </span> </span>);
      } else if(node.level == 2){
          html = (<span class="custom-tree-node" >
            <span class="tree-title"> {node.label} </span> <span class="node-tool-bar" >
                <el-button v-preventReClick type="text"
                    class="el-icon-edit-outline"
                    title="重命名"
                    on-click={(e) => this.edit(data, e)} >
                </el-button>

                <el-button v-preventReClick type="text"
                    class="el-icon-delete"
                    title="删除"
                    on-click={(e) => this.remove(data, node, e)} >
                </el-button> </span> </span>);
      }
      return html;
    },
    // tree - 添加
    append(data, node, e){
      e.preventDefault();
      e.stopPropagation();
      let _this = this, arr = this.data[0].children
      this.departType = data.departmentName == '内部数据' ? 0 : 1
      // this.curDepar.queryConditionList = data.

      this.departBool = true
      this.department_child = true
    },
    doAddDepart: function (val) {
      val.parentId = this.data[0].companyId
      // console.log("doAddDepart:", val);
      this.$http.post(this.API.addDepartmentForCompany(this.modelId), val)
        .then((res) => {
          // let res = JSON.parse(body)
          // console.log(res);
          if (res.code == "000") {
            this.department_child = false
            this.initDepartData();
            this.$message.success("操作成功");
          } else {
            this.$message.info(res.msg);
          }
        }).catch(res => {
          // console.log(res);
        });
    }, //doAddDepart
    // tree - 删除
    remove(data, node, e){
      e.preventDefault();
      e.stopPropagation();
      // console.log(data)
      this.$confirm('是否删除该部门?', '提示', {
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'btn_custom_cancel',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.API.delDpeartmentForCompany(this.modelId), [data.id])
        .then((res) => {
          // let res = JSON.parse(body)
          if (res.code == "000") {
            this.initDepartData();
            this.$message.success("删除成功");
          } else {
            this.$message.info(res.msg);
          }
        })
      }).catch(() => {})
    },
    // tree - 点击触发
    treeClick(data, node){
      // console.log(node, data)
      this.tjgroup = '条件组查询'

      this.pageNum = 1
      this.photoFlag = 2
      this.searchKey = ''
      this.queryConditionArr = []
      if(node.level == 1){
        this.deparmentId = this.companyId
        this.intefaceType = 'card'
        this.externalCode = ''
      } else if(node.level == 2){
        this.deparmentId = data.id
        this.externalCode = 0
        this.intefaceType = 'cardDeparment'
        // console.log(this.externalCode)
      } 
      
      this.getProsonData()
    },

    // tree - 编辑
    edit(data, e){
      e.preventDefault();
      e.stopPropagation();
      let _this = this, arr = this.data[0].children[0].children
      this.departType = data.departmentName == '内部数据' ? 0 : 1
      this.curDepar.queryConditionList = data.QueryConditionString
      this.curDepar = data

      this.departBool = false
      this.department_child = true
    },//renameDepart
    editAddDepart(depart){
      depart.parentId = this.data[0].companyId
      this.$http.post(this.API.editDepartmentNameForCompany(this.modelId), depart)
        .then((res) => {
        // let res = JSON.parse(body)
        if (res.code == "000") {
            this.department_child = false
            this.initDepartData();
          this.$message.success("操作成功");
        } else {
          this.$message.info(res.msg);
        }
      })
    },
    // 搜索按钮
    searchBtn(){
      this.pageNum = 1
      this.getProsonData()
    },

    relation() {
      let child = this.$refs.NoPhotos,
      userId = child.userId,
      fileInfoId = child.fileInfoId

      if(!userId) {
        this.$message.error('请勾选数据!')
        return 
      }
      if(!fileInfoId) {
        this.$message.error('请勾选相片!')
        return 
      }

      this.$http.post(this.API.matchingUserPhoto(userId, fileInfoId))
        .then((res) => {
          // let res = JSON.parse(body)
          console.log(res)
          if(res.code == '000'){
            this.$message.success('关联成功！')
            // this.noPhotos_child = false
            // 获取人员
            child.pageNum = 1
            child.getNoPerosn()

            // 获取图片
            child.ipageNum = 1
            child.getNoPhoto()
          } else {
            // this.$message.success(res.msg)
          }
        })
    },
    // 分页方法
    sizeChange(val){
      this.pageNum = 1
      this.pageSize = val

      localStorage.setItem('pages', val)

      this.getProsonData()
    },
    curChange(val){
      this.pageNum = val

      this.getProsonData()
    },
    
    // 数据全选
    selectAll(val) {
      this.$refs.singleTable.clearSelection();
      this.allPerson = val
      console.log(val, this.allPerson)
      if(val) {
        let obj = {
          contanUserIdArr: [],
          ifContanUserIdArr: false,
          queryConditionArr: this.queryConditionArr
        }
        
        this.$http.post(this.API.conditionQuerys(this.deparmentId, 1, 10000, this.externalCode, this.searchKey, this.photoFlag, this.intefaceType, this.modelId), obj)
          .then((res) => {
            // let res = JSON.parse(body)
            if (res.code == "000") {
              this.batchdata = res.data
              this.updateCardPerson(res.data)
            } else {
              this.updateCardPerson([])
            }
          })
      } else {
        this.updateCardPerson([])
      }
    },

    // 勾选数据
    batchDel(val) {
      this.batchdata = val
      this.allPerson = false

      this.updateCardPerson(val)
    },
    // 编辑
    updateUser(data){
      this.editPersonId = data.id
      this.editPeroson_child = true
    },

    // 添加人员 - 下拉
    addPersonL(command){
      if(command == 'a'){
        this.addPeroson_child = true
      } else if(command == 'b') {
        this.importPeroson_child = true
      } else if(command == 'c'){
        // 处理数据 取出id
        let id = []
        this.batchdata.filter(item => id.push(item.id))
        if(id.length == 0){
          this.$message.error('请勾选数据!')
          return 
        }

        this.$confirm('是否删除选中的数据?', '提示', {  
          closeOnPressEscape: false,
          closeOnClickModal: false,
          cancelButtonClass: 'btn_custom_cancel',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(this.API.delHzUser(this.modelId), id)
            .then((res) => {
              // let res = JSON.parse(body)
              if(res.code == '000'){
                this.$message.success('删除成功！')
                this.pageNum = 1
                this.getProsonData()
              } else {
                this.$message.error(res.msg)
              }
            })
        }).catch(() => {})
      } else if(command == 'd') {
        this.$confirm('是否删除所有数据?', '提示', {  
          closeOnPressEscape: false,
          closeOnClickModal: false,
          cancelButtonClass: 'btn_custom_cancel',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(this.API.delectAllUser(this.modelId))
            .then((res) => {
              // let res = JSON.parse(body)
              if(res.code == '000') {
                this.$message.success('删除成功！')
                this.pageNum = 1
                this.getProsonData()
              } else {
                this.$message.error(res.msg)
              }
            })
        })
      } else if(command == 'e'){
        this.meetingInport_child = true
      } else if(command == 'f') {
        this.photoFlag = 2
        this.searchKey = ''
        this.queryDuplicate()
      } else if(command == 'g') {
        this.exportPerson()
      } else if(command == 'h') {
        this.$http.post(this.API.downloadExcelLog)
        .then((res) => {
          // let res = JSON.parse(body)
          console.log(res)
          if(res.code == '000') {
            let aDom = document.createElement('a')
            aDom.setAttribute('href', "	https://hzcard-1305256445.cos.ap-guangzhou.myqcloud.com" + res.data)
            aDom.setAttribute('download', this.fileTitle + '.xlsx')
            aDom.click()
          }
        })
      }
    },

    // 导出人员
    exportPerson() {
      if(this.batchdata.length == 0) {
        this.$message.info('请勾选导出人员!')
        return 
      }

      let tHeader = [], filterVal = [], tableData = [...this.batchdata],
      obj = {
        contanUserIdArr: [],
        ifContanUserIdArr: false,
        queryConditionArr: this.queryConditionArr
      }
      this.tableCate.filter(item => {
        tHeader.push(item.label)
        filterVal.push(item.props)
      })

      exportToExcel(tableData, '导出人员', tHeader, filterVal, res => { })
    },

    // 相片管理 - 下拉
    handleCommand(command){
      if(command == 'a'){
        this.updatePhoto_child = true
      } else if(command == 'b'){
        this.autoPhoto()
      } else if(command == 'c'){
        this.noPhotos_child = true
      } else if(command == 'e') {
        this.iptCpdPackage()
      }
    },
    // 关闭组件
    closeComponent() {
      this.importPeroson_child = false
      this.getProsonData()
    },
    // 自定匹配
    autoPhoto() {
      this.$http.post(this.API.photoMatching(this.modelId))
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000'){
            this.$message.success('匹配完成！')
            this.getProsonData()
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 重名
    queryDuplicate(){
      this.$http.post(this.API.findDuplicateName(this.pageNum, this.pageSize, this.externalCode, this.modelId))
        .then((res) => {
          // let res = JSON.parse(body)
          // console.log(res)
          if (res.code == "000") {
            this.total = res.count
            this.tableData = res.data
          } else {
            this.total = 0;
            this.tableData = [];
          }
        })
    },

    // 会议导入人员
    importPerson() {
      let child = this.$refs.meetingInport,
      batchdata = child.batchdata

      if(batchdata.length == 0) {
        this.$message.info('请勾选人员')
        return 
      }

      let ids = batchdata.map(its => its.id)

      this.$http.post(this.API.meetingToCardUser(this.modelId), ids)
        .then(res => {
          if(res.code == '000') {
            this.$message.success('导入成功！')
            this.meetingInport_child = false

            this.getProsonData()
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 保存 - 添加人员
    submitForm(){
      let child = this.$refs.AddPerson, from = {...child.user}
      from.cardNum = from.cardNum * 1
      if(from.cardNum == 0) {
        this.$message.info('数量不能为0')
        return
      }

      this.$http.post(this.API.addUser(this.modelId), from)
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000'){
            this.$message.success('添加成功！')
            this.addPeroson_child = false
            this.getProsonData()
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 保存 - 修改人员
    editSave(){
      let child = this.$refs.EditPerson, from = {...child.user}
      from.cardNum = from.cardNum * 1
      if(from.cardNum == 0) {
        this.$message.info('数量不能为0')
        return
      }

      this.$http.post(this.API.updateUser(this.modelId), from)
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000'){
            this.$message.success('修改成功！')
            this.editPeroson_child = false
            this.getProsonData()
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    setShowNum(num){
      this.showNum = num
    },
    imgUploadAll(e){
      let child = this.$refs.UpdatePhoto
      child.imgUploadAll()
    },

    cancel() {
      this.addPeroson_child = false
      this.editPeroson_child = false
      this.noPhotos_child = false
      this.updatePhoto_child = false
      this.importPeroson_child = false
      this.department_child = false
      this.sendCrd_child = false
      this.address_switch = false

      this.showNum = 0
      this.pageNum = 1
      this.getProsonData()
    },
    close() {
      // this.condi_child = false
      // this.getCondit()
      this.department_child = false
      this.meetingInport_child = false
    },

    // 获取人员数据
    getProsonData(){
      let obj = {
        contanUserIdArr: [],
        ifContanUserIdArr: false,
        queryConditionArr: this.queryConditionArr
      }
      
      this.$http.post(this.API.conditionQuerys(this.deparmentId, this.pageNum, this.pageSize, this.externalCode, this.searchKey, this.photoFlag, this.intefaceType, this.modelId), obj)
        .then((res) => {
          // let res = JSON.parse(body)
          console.log(res)
          if (res.code == "000") {
            res.data.filter((its, index) => its.index = index + 1 + (this.pageNum - 1) * this.pageSize)
            this.total = res.count
            this.tableData = res.data
            
            // 回显选中人员
            let all = [...this.cardPerson]

            if(this.allPerson){
              this.updateCardPerson(res.data)
            }

            if(all.length > this.pageSize) {
              this.allPerson = true
              this.$refs.singleTable.clearSelection();
            } else {
              this.allPerson = false
              let more = this.tableData.filter(its => all.map(i => i.id).includes(its.id) && its)
  
              setTimeout(() => {
                more.forEach(row => {
                  this.$refs.singleTable.toggleRowSelection(row);
                });
              }, 500)
            }
          } else {
            this.total = 0;
            this.tableData = [];
          } 
        }).catch(res => {
          console.log(res)
          this.total = 0;
          this.tableData = [];
        })
    },
    // 获取部门
    initDepartData() {
      this.$http.post(this.API.findCompanyDepartment(this.modelId))
      .then((res) => {
        // let res = JSON.parse(body)
        // console.log(res)
        if(res.code == '000'){
          let arr = []
          this.companyId = res.data[0].companyId
          res.data.filter(item => {
            if(item.departmentName == item.companyName){
              // this.deparmentId = item.companyId
              this.data = [
                {
                  companyId: item.companyId,
                  departmentName: '分组定义',
                  // departmentName: item.companyName,
                  queryConditionList: item.QueryConditionString,
                  id: item.id,
                  children: [
                    // { departmentName: '内部人员', id: 0, children: []}, 
                    // { departmentName: '外部人员', id: 1 }, 
                  ]
                }
              ]
            } else {
              arr.push(item)
            }
          })
        
          this.data[0].children = arr;
          this.deparmentId = this.companyId
          
          setTimeout(() => {
            this.$refs['person-tree'].setCurrentKey(this.companyId)
            document.querySelector('.el-tree-node__content').style = `
              background-color: #e67c7c;
              color: #fff
            `
            this.getProsonData()
          }, 300)
        }
      })
    },
    // 查询地址管理数据
    getAddressData() {
      this.$http.post(this.API.findHarvestAddress)
        .then(res => {
          if(res.code == '000') {
            res.data.filter(its => {
              its.select = its.defaultOption == 1 ? true : false
              its.address = its.region + '\r\n' + its.detailedAddress
            })
            this.addresslist = res.data
          } else {
            this.addresslist = []
          }
        })
    }
  },
  computed: {
    ...mapState(['curCardModel', 'modelId', 'cardPerson'])
  },
  mounted() {
    let {model, collection} = this.curCardModel
    this.model = model
    this.collection = collection
    this.varName = echo({collection, model, vue: this})

    this.pageSize = localStorage.getItem('pages')*1 || 100

    var dom = document.querySelector('.table')
    this.height = dom.offsetHeight

    console.log(this.curCardModel)
    // 动态获取 表头
    for(let i of this.varName ) {
      attrList[i] && this.tableCate.push({props: i, label: attrList[i]})
    }

    this.initDepartData()
    // this.getProsonData()
  }
}
</script>

<style>
.dataInput .el-tree-node__content {
  height: 32px !important;
}
</style>

<style lang="less" scoped>
.dataInput {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #eee;

  .echo-model {
    .dataInputModel, .back-dataInputModel {
      width: 500px;
      height: 281px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin: 10px;
      background-color: #fff;
      
      .templateOS {
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        border: 1px dashed transparent;
        position: absolute;
        min-width: 40px;
        min-height: 20px;
        display: none;
        outline: none;
        transform: translate(20px, 20px);
        background-repeat: no-repeat;
        background-size: 100% 100%;
  
        .cte {
          width: 100%;
          height: 100%;
          // word-wrap: break-word;
          word-break: normal;
          overflow: hidden;
        }
      }
    }
    
  }

  .operat {
    width: 240px;
    height: 100%;
    box-sizing: border-box;
    padding-right: 20px;
    background-color: #fff;
  }

  .person {
    height: 100%;
    background-color: #fff;
    padding: 0 10px;
    box-sizing: border-box;
    // background-color: skyblue;

    .func {
      height: 32px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }

    .table {
      height: calc(100% - 84px);
      width: 100%;
      box-sizing: border-box;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: middle;
        margin: 0 auto;
        border-style: none;
      }
    }

    .pagin {
      align-items: center;
      justify-content: space-between;
    }
  }

  .choice-address {
    width: 100%;

    .address-region {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      height: 42px;
      padding-bottom: 10px;
    }
    
    .radio {
      width: 18px;
      height: 18px;
      margin-right: 15px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #ccc;
      position: relative;
      margin: auto;
    }

    .select {
      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #409eff;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
      }
    }
  }
}
</style>