import $ from 'jquery'
let copy, bl = 3.78
let url = `https://backgroupcard-1305256445.cos.ap-guangzhou.myqcloud.com/`

export function echo({collection, model, vue}) {
  window.vue = vue
  // 更换类名
  $('.info').removeClass('info')

  // 复制节点 防止被清空
  copy = $('.xcopy').clone(true)
  console.log(copy)

  // 收集变量名
  let varName = []

  console.log(collection)

  for(let i = 0; i < collection.length; i++) {
    var curObj = collection[i], side = curObj.side, dom
    curObj.variable && varName.push(curObj.variable)
    
    if(side == 'twoSided') dom = '.back-dataInputModel'
    else dom = '.dataInputModel'
    var node = $(copy).clone(true).attr({
      'id': curObj.id
    }).css({
      'display': 'block',
      'transform': 'translate(' + curObj.x * bl + 'px,' + curObj.y * bl + 'px)'
    }).addClass('record')

    // 样式回显
    styleEcho(node, curObj)

    console.log($(dom))
    $(dom).append(node)
  }

  // model 样式回显
  $('.dataInputModel').css({
    'width': model.width * bl+'px',
    'height': model.height * bl+'px',
    'background-color': model.color,
    'background-image': `url(${url + model.image})`,
  })
  $('.back-dataInputModel').css({
    'width': model.width * bl+'px',
    'height': model.height * bl+'px',
    'background-color': model.backColor,
    'background-image': `url(${url + model.backImage})`,
  })

  $('.person').css('width', `calc(100% - ${240 + model.width * bl + 20}px)`)

  return varName
}

function styleEcho(tNode, curObj) {
  let { boxshadow, border } = curObj
  if(curObj.eleType == 'image'){
    $(tNode).css({
      'background-image': `url(${url + curObj.image})`,
    });
  } else if(curObj.eleType == 'text') {
    // font 相关
    let { font, conten } = curObj
    $(tNode).find('.cte').html(conten);
    $(tNode).find('.cte').css({
      'font-family': font.family,
      'font-size': font.size+'px',
      'letter-spacing': font.spacing+'px',
      'line-height': font.lineHeight * bl+'px',
      'color': font.color,
      'text-align': font.textAlign,
      'text-align-last': font.textAlign == 'justify' ? 'justify' : 'inherit',
      'font-weight': font.fontWeight,
      'text-decoration': font.textDecoration,
      'font-style': font.italics,
    })
  }

  $(tNode).css({
    'width': curObj.width * bl + 'px',
    'height': curObj.height * bl + 'px',
    'opacity': curObj.opacity / 100,
    'border': `${border.width}px ${border.style} ${border.color}` ,
    'border-radius': border.radius + 'px',
    'box-shadow': `${boxshadow.x}px ${boxshadow.y}px ${boxshadow.vague}px ${boxshadow.size}px ${boxshadow.color}`
  });

  if(curObj.variable) {
    // $(tNode).css('border', `1px solid red`);
    $(tNode).find('.cte').html('');
    $(tNode).find('.cte').addClass(curObj.variable)
  }
}


export const attrList = {
  userName: '姓名',
  characterId: '角色',
  phone: '手机',
  oldDepartmentName: '部门',
  attribute1: '组别',
  ranksId: '级别',
  attribute2: '分团',
  // photoFileSaveName: '头像'
}